import { useContext } from 'react'

import { SL_Modal } from '../../../../components/modal/Modal'
import { SL_Button } from '../../../../components/buttons/Button'

import { displayMessage } from '../../../../helpers/messages'
import { SirenContext } from '../../../../context/SirenContext/SirenContext'

export const SoundPermissionModal = ({ onClose }) => {
    const { setPlaySiren } = useContext(SirenContext)

    const enableSound = () => {
        setPlaySiren(true)
        onClose()
    }
    return (
        <SL_Modal
            open={true}
            closable={false}
            closeIcon={null}
            footer={null}
            width={'65vh'}
            title={null}
            onCancel={onClose}
            bodyStyle={{
                padding: 12,
            }}
        >
            <div className="confirm-modal">
                <div className="confirm-modal-text">{displayMessage('ENABLE_SOUND_NOTIFICATIONS')}</div>
                <div className="flex gap-3 w-full items-end justify-end">
                    <SL_Button onClick={onClose} type="close" block className="bg-white/10 hover:bg-white/20 confirm-modal-button confirm-modal-text">
                        {displayMessage('NO')}
                    </SL_Button>
                    <SL_Button type="normal" onClick={enableSound} block className="bg-primary-500 hover:bg-primary-100 confirm-modal-button confirm-modal-text">
                        {displayMessage('YES')}
                    </SL_Button>
                </div>
            </div>
        </SL_Modal>
    )
}
