import { useContext, useEffect, useState } from 'react'
import { Tag } from 'antd'
import { ArrowUpOutlined } from '@ant-design/icons'

import logo from '../../../assets/logo-writed.svg'
import { getIconByCode, hexToRgba } from '../../../helpers/helper'
import { ECentralAlertTypes } from '../../../enums/ECentralAlertTypes'
import { ECentralActions } from '../../../enums/ECentralActions'
import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { colors } from '../../../services/style'

export const Header = ({ alerts, centrals, handleNotificationClick }) => {
    const [prioritaryAlert, setPrioritaryAlert] = useState(null)
    const [count, setCount] = useState(0)
    const { mobile_mode } = useContext(GeneralContext)

    const alert_type_label = Object.values(ECentralAlertTypes).find((type) => type.code == prioritaryAlert?.alert_type)?.label || ''

    const handleNotifications = (alerts_to_show) => {
        if (!alerts_to_show) {
            setPrioritaryAlert(null)
            setCount(5)
            return
        }
        const prioritary = alerts_to_show.sort((a, b) => a.priority - b.priority)[0]

        setPrioritaryAlert(prioritary)
        let newCount = -1
        alerts_to_show.forEach((al) => {
            Object.values(al.alerts).forEach((type) => {
                newCount += type.quantity
            })
        })
        setCount(newCount)
    }

    useEffect(() => {
        const centrals_ids = centrals.map((central) => central.central_id)
        const alerts_to_show = alerts ? Object.values(alerts).filter((alert) => !alert.isVisible && centrals_ids.includes(alert.central_id)) : null
        handleNotifications(alerts_to_show)
    }, [alerts, centrals])

    return (
        <div
            className={`flex justify-between items-center text-white flex-wrap gap-2 -mt-2 -mx-2 header ${mobile_mode ? 'flex-col' : ''}`}
            id="header"
            style={prioritaryAlert && { background: `linear-gradient(${hexToRgba(prioritaryAlert?.notification_color, 0.5)}, ${colors['sl-gray'][900]})` }}
        >
            <img src={logo} className="logo-header"></img>

            {prioritaryAlert && (
                <Tag
                    color={prioritaryAlert.notification_color}
                    className="flex justify-between items-center px-4 rounded-[120px] notification-alert"
                    style={{
                        boxShadow: `0px 0px 20px 7px ${hexToRgba(prioritaryAlert?.notification_color, 0.5)}`,
                    }}
                >
                    <div className="flex justify-center items-center gap-1 uppercase notification-alert-type">
                        {getIconByCode(prioritaryAlert.alert_type, '#ffffff', '3.7vh')}
                        <span className="notification-alert-type-title uppercase text-medium">{alert_type_label}</span>
                    </div>

                    <div className="flex justify-center items-center gap-2 uppercase notification-alert-central text-medium">
                        <span>{prioritaryAlert.central_name}</span>
                        {count > 0 && <span> +{count} </span>}
                    </div>

                    <Tag color="white" className="flex justify-center items-center  rounded-[120px]  cursor-pointer notification-alert-see-now " onClick={() => handleNotificationClick()}>
                        <div className="notification-alert-see-now-text" style={{ color: prioritaryAlert.notification_color }}>
                            {displayMessage('SEE_NOW')}
                        </div>
                        <ArrowUpOutlined style={{ color: prioritaryAlert.notification_color }} className="notification-alert-see-now-icon" />
                    </Tag>
                </Tag>
            )}

            <div></div>
        </div>
    )
}
