import { Question } from '@phosphor-icons/react'
import { ECentralAlertTypes } from '../enums/ECentralAlertTypes'
import EUserTypes from '../enums/EUserTypes'
import { AlertSvg } from '../components/icons/AlertSVG'
import { CentralFailSvg } from '../components/icons//CentralFailSvg'
import { FireSvg } from '../components/icons//FireSvg'
import { SupervisionSvg } from '../components/icons//SupervisionSvg'
import { XSvg } from '../components/icons/XSvg'

export function formatCpf(cpf) {
    const inputCpf = cpf.replace(/\D/g, '')

    return inputCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function formatCnpj(cnpj) {
    if (!cnpj) return ''

    const inputCnpj = cnpj.replace(/\D/g, '')

    return inputCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export function formatCnae(cnae_number) {
    if (!cnae_number) return ''

    const inputCnae = cnae_number.replace(/\D/g, '')

    return inputCnae.replace(/(\d{2})(\d{2})(\d{1})(\d{2})/, '$1.$2-$3-$4')
}

export function formatPhone(ddd, phone) {
    if (!ddd || !phone) return
    const inputPhone = phone.replace(/\D/g, '')
    if (!ddd) {
        return inputPhone.replace(/(\d{5})(\d{4})/, '$1-$2')
    } else {
        return `(${ddd}) ${inputPhone.substring(0, 5)}-${inputPhone.substring(5)}`
    }
}

export function findUserTypeByCode(code) {
    for (const userType in EUserTypes) {
        if (EUserTypes[userType].code == code) {
            return EUserTypes[userType]
        }
    }

    return null
}

// export function findClientTypeByCode(code) {
//     for (const clientType in EClientTypes) {
//         if (EClientTypes[clientType].code == code) {
//             return EClientTypes[clientType]
//         }
//     }
//     return null
// }

export function extractNumbersFromString(str) {
    return str.replace(/\D/g, '')
}

export function emptyString(str) {
    if (!str) return true

    if (str == '') return true

    if (str.length == '') return true

    if (str == undefined) return true

    if (str == null) return true

    return false
}

export function getIcon(code, color, size = 24) {
    if (!color) color = '#ffffff'
    switch (`${code}`) {
        case ECentralAlertTypes.FIRE.type:
            return <FireSvg color={color} size={size}></FireSvg>
        case ECentralAlertTypes.SUPERVISION.type:
            return <SupervisionSvg color={color} size={size}></SupervisionSvg>
        case ECentralAlertTypes.FAIL.type:
            return <AlertSvg color={color} size={size}></AlertSvg>
        case ECentralAlertTypes.DISABLE.type:
            return <XSvg color={color} size={size}></XSvg>
        case ECentralAlertTypes.CENTRAL_FAIL.type:
            return <CentralFailSvg color={color} size={size}></CentralFailSvg>
        default:
            return <Question color={color} size={size} />
    }
}

export function getIconByCode(code, color, size = 24) {
    if (!color) color = '#ffffff'
    code = code == '' ? code : parseInt(code)
    switch (code) {
        case ECentralAlertTypes.FIRE.code:
            return <FireSvg color={color} size={size}></FireSvg>
        case ECentralAlertTypes.SUPERVISION.code:
            return <SupervisionSvg color={color} size={size}></SupervisionSvg>
        case ECentralAlertTypes.FAIL.code:
            return <AlertSvg color={color} size={size}></AlertSvg>
        case ECentralAlertTypes.DISABLE.code:
            return <XSvg color={color} size={size}></XSvg>
        case ECentralAlertTypes.CENTRAL_FAIL.code:
            return <CentralFailSvg color={color} size={size}></CentralFailSvg>
        default:
            return <Question color={color} size={size} />
    }
}

export function getColorByLuminosity(color) {
    if (!color) return '#fff'
    const r = parseInt(color[1] + color[2], 16)
    const g = parseInt(color[3] + color[4], 16)
    const b = parseInt(color[5] + color[6], 16)

    const luminosidade = (r * 299 + g * 587 + b * 114) / 1000

    return luminosidade > 128 ? '#000' : '#fff'
}

export function hexToRgba(hex, opacity) {
    if (!hex) return ''
    hex = hex.replace('#', '')

    var r = parseInt(hex.substring(0, 2), 16)
    var g = parseInt(hex.substring(2, 4), 16)
    var b = parseInt(hex.substring(4, 6), 16)

    return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export function convertSizes(pixelsToCalculate, windowHeight = 0, windowWidth = 0) {
    const referenceHeigth = 1080
    const referenceWeigth = 1920
    const pixelsToCalculateInVH = (pixelsToCalculate / referenceHeigth) * 100
    const pixelsToCalculateInVW = (pixelsToCalculate / referenceWeigth) * 100

    const heigth = (pixelsToCalculateInVH / 100) * windowHeight
    const width = (pixelsToCalculateInVW / 100) * windowWidth

    return { heigth, width }
}
