import { useContext } from 'react'
import { Dropdown } from 'antd'

import arrowIcon from '../../../assets/icons/arrow_down.svg'

import { displayMessage } from '../../../helpers/messages'

import { ETimes } from '../../../enums/ETimes'
import { ECentralAlertTypes } from '../../../enums/ECentralAlertTypes'

import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { getIconByCode } from '../../../helpers/helper'
import { colors } from '../../../services/style'
import api from '../../../services/api'

export const LastAlerts = ({ data, time, onChangeTime, onChangeAlertsValue, onClickAlert }) => {
    const items = Object.values(ETimes).map((t) => {
        return {
            ...t,
            key: t.value,
            label: <span className="text-white nav-bar-menu-option-text">{t.label}</span>,
        }
    })

    const { mobile_mode, resolution_4K } = useContext(GeneralContext)

    const alertTypes = {}
    Object.values(ECentralAlertTypes).forEach((cat) => {
        alertTypes[cat.code] = cat
    })

    const onChangeTimeValue = async (value) => {
        onChangeTime(value)
        try {
            let limit_for_last_alerts
            if (mobile_mode) {
                limit_for_last_alerts = 2
            } else if (resolution_4K) {
                limit_for_last_alerts = 12
            } else {
                limit_for_last_alerts = 8
            }

            const { data } = await api.alertLogs.lastAlertsByTime({ time: value, limit_for_last_alerts })
            onChangeAlertsValue(data)
        } catch (error) {
            console.log(error)
        }
    }

    const getData = (code, data) => {
        return alertTypes[code][data]
    }

    const getSelectedTime = () => {
        const selectedTime = Object.values(ETimes).find((t) => t.value == time)?.label

        return selectedTime || ETimes.TODAY.label
    }

    return (
        <div className="bg-sl-gray-1000 -mx-2 -mb-2 flex flex-col last-alerts" id="lastAlerts">
            <div className="flex justify-between items-center">
                <span className={`text-sl-gray-0 uppercase title opacity-40 last-alerts-title-text`}>{displayMessage('LAST_ALERTS')}</span>

                <Dropdown menu={{ items, className: 'navbar-menu', style: { backgroundColor: colors['sl-gray'][1000], color: 'white' }, onClick: (e) => onChangeTimeValue(e.key) }}>
                    <div className="flex gap-[6px] items-center">
                        <div className={`opacity-70 last-alerts-title-text`}>{getSelectedTime()}</div>
                        <img src={arrowIcon} className="notification-alert-see-now-icon " />
                    </div>
                </Dropdown>
            </div>

            {data.length ? (
                <div className={`flex flex-wrap last-alerts-alerts`}>
                    {data.map((alert) => (
                        <div
                            key={`alert-${alert.id}`}
                            className="flex justify-between items-center rounded-lg p-3 cursor-pointer text-xs bg-sl-gray-100 last-alerts-alert"
                            style={{ border: `solid 1px ${colors['sl-gray'][150]}` }}
                            onClick={() => onClickAlert(alert.id)}
                        >
                            <div className="flex items-center gap-[10px]">
                                <div className="rounded-full p-1 bg-sl-gray-100 flex justify-center items-center last-alerts-icon-div" style={{ border: `solid 1px ${colors['sl-gray'][150]}` }}>
                                    {getIconByCode(alert.type, alert.color, '1.85vh')}
                                </div>
                                <div className="flex flex-col uppercase last-alerts-alert-data">
                                    <span style={{ color: alert.color }} className="last-alerts-alert-data-alert-name text-medium">
                                        {getData(alert.type, 'label')}
                                    </span>
                                    <span className="last-alerts-alert-data-central-name">{alert.label}</span>
                                </div>
                            </div>

                            {/* verificar essa div nas telas maiores */}

                            <div className="flex items-center gap-[10px] opacity-40">
                                <div className="flex flex-col items-end gap-1">
                                    <span className={`text-sl-gray-0 last-alerts-alert-data-date`}>{alert.date}</span>
                                    <span className={`text-sl-gray-0 last-alerts-alert-data-date `}>{alert.minutes}</span>
                                </div>
                                <span className="rounded-[5px] text-medium gradient-button last-alerts-alert-data-button" style={{ border: `1px solid ${colors['sl-gray'][150]}` }}>
                                    Abrir
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex justify-center items-center central-no-centrals-text text-white">{displayMessage('NO_ALERTS_ON_PERIOD')}</div>
            )}
        </div>
    )
}
