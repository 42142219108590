import { ConfigProvider, Select } from 'antd'
import { colors } from '../../services/style'

export const GS_Select = ({ ...rest }) => {
    const options = rest?.options?.map((option) => ({ ...option, className: 'text-[1.5vh]' }))
    return (
        <ConfigProvider
            theme={{
                token: {
                    Select: {
                        colorText: colors['sl-gray'][300],
                        colorTextPlaceholder: colors['sl-gray'][300],
                        colorTextDisabled: 'white',
                    },
                },
            }}
        >
            <Select dropdownStyle={{ backgroundColor: colors['sl-gray'][700], color: 'white' }} {...rest} options={options} />
        </ConfigProvider>
    )
}
