import { useContext, useEffect, useState } from 'react'
import { ConfigProvider, Divider, Menu, Spin } from 'antd'
import { X } from '@phosphor-icons/react'

import centralsIcon from '../../assets/icons/central.svg'
import usersIcon from '../../assets/icons/users.svg'
import groupIcon from '../../assets/icons/four_squares.svg'
import notificationIcon from '../../assets/icons/bell.svg'
import arrowIcon from '../../assets/icons/small_arrow_rigth.svg'
import buildingsIcon from '../../assets/icons/buildings.svg'

import { SL_Modal } from '../../components/modal/Modal'
import { UsersOption } from './users/UsersOption'
import { CompaniesOption } from './companies/CompaniesOption'
import { CentralsOption } from './centrals/CentralsOption'
import { GroupOption } from './groups/GroupOption'
import { NotificationsOption } from './notifications/NotificationsOption'

import { colors } from '../../services/style'
import { displayMessage } from '../../helpers/messages'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'

export const SettingsModal = ({ onClose }) => {
    const { windowHeight, windowWidth, mobile_mode } = useContext(GeneralContext)
    const [selectedMenu, setSelectedMenu] = useState('centrals')
    const [title, setTitle] = useState(<span className="text-white settings-modal-configs-title">{displayMessage('CENTRALS')}</span>)
    const [sreen, setScreen] = useState(null)
    const [showLoading, setShowLoading] = useState(false)

    const modalWidth = windowWidth * (mobile_mode ? 0.9 : 0.7)

    const menuItems = [
        {
            key: 'centrals',
            image: centralsIcon,
            item_label: displayMessage('CENTRALS'),
            title: displayMessage('CENTRALS'),
        },
        {
            type: 'divider',
        },
        {
            key: 'companies',
            image: buildingsIcon,
            item_label: displayMessage('COMPANIES'),
            title: displayMessage('COMPANIES'),
        },
        {
            type: 'divider',
        },
        {
            key: 'grups',
            image: groupIcon,
            item_label: displayMessage('GROUPS'),
            title: displayMessage('GROUPS'),
        },
        {
            type: 'divider',
        },
        {
            key: 'notifications',
            image: notificationIcon,
            item_label: displayMessage('NOTIFICATIONS'),
            title: displayMessage('NOTIFICATIONS'),
        },
        {
            type: 'divider',
        },
        {
            key: 'users',
            image: usersIcon,
            item_label: displayMessage('USERS'),
            title: displayMessage('USERS'),
        },
    ]

    const items = menuItems.map((item) => ({
        ...item,
        label: (
            <div className="flex items-center justify-between h-full settings-modal-menu-item gap-3">
                <div className="flex gap-3 items-center ">
                    {item.image ? <img src={item.image} className="settings-modal-menu-item-icon" /> : item.item_icon}
                    {!mobile_mode && <span className="text-white">{item.item_label}</span>}
                </div>
                {!mobile_mode && <img src={arrowIcon} className="settings-modal-menu-item-chevron-icon" />}
            </div>
        ),
    }))

    useEffect(() => {
        const selected = menuItems.find((menu) => menu.key == selectedMenu)
        setTitle(<span className="text-white settings-modal-configs-title">{selected.item_label}</span>)
        switch (selectedMenu) {
            case 'centrals':
                setScreen(<CentralsOption onChangeTitle={(newTitle) => setTitle(newTitle)} setShowLoading={(value) => setShowLoading(value)} />)
                break
            case 'users':
                setScreen(<UsersOption onChangeTitle={(newTitle) => setTitle(newTitle)} setShowLoading={(value) => setShowLoading(value)} />)
                break
            case 'grups':
                setScreen(<GroupOption onChangeTitle={(newTitle) => setTitle(newTitle)} setShowLoading={(value) => setShowLoading(value)} />)
                break
            case 'notifications':
                setScreen(<NotificationsOption setShowLoading={(value) => setShowLoading(value)} />)
                break
            case 'companies':
                setScreen(<CompaniesOption onChangeTitle={(newTitle) => setTitle(newTitle)} setShowLoading={(value) => setShowLoading(value)} />)
                break
        }
    }, [selectedMenu])

    useEffect(() => {
        if (!title) {
            if (selectedMenu) {
                const selected = menuItems.find((menu) => menu.key == selectedMenu)
                setTitle(<span className="text-white settings-modal-configs-title">{selected.item_label}</span>)
            } else {
                setTitle(null)
            }
        }
    }, [title, selectedMenu])
    return (
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        darkItemSelectedBg: colors['sl-gray'][1000],
                        darkItemBg: colors['sl-gray'][1000],
                        darkIitemActiveBg: colors['sl-gray'][1000],
                        darkItemHoverColor: colors['sl-gray'][50],
                        darkItemColor: colors['sl-gray'][50],
                        darkIitemSelectedColor: colors['sl-gray'][50],
                        itemMarginInline: 0,
                        itemPaddingInline: 0,
                    },
                },
                token: {
                    colorSplit: colors['sl-gray'][100],
                    controlHeightLG: windowHeight * 0.08,
                },
            }}
        >
            <SL_Modal
                open={true}
                closable={false}
                closeIcon={null}
                footer={null}
                width={modalWidth}
                bodyStyle={{
                    height: windowHeight * 0.8,
                    display: 'flex',
                    padding: 12,
                }}
            >
                <div style={{ borderRight: `1px solid ${colors['sl-gray'][100]}`, marginRigth: '-1px' }} className={`h-full settings-modal-menu p-3 gap-6`}>
                    <X color={colors['sl-gray'][50]} className="cursor-pointer settings-modal-configs-icon" onClick={onClose} />
                    <Menu onClick={(e) => setSelectedMenu(e.key)} theme="dark" mode="vertical" items={items} getPopupContainer={(node) => node.parentNode} inlineCollapsed={mobile_mode} />
                </div>
                <div className="settings-modal-configs-view-area">
                    {title && <div className={`flex items-center settings-modal-configs-title-div ${mobile_mode ? 'px-2' : 'px-6'}`}>{title}</div>}
                    <Divider className="m-0" />
                    <Spin spinning={showLoading} delay={500}>
                        <div
                            className="p-6 overflow-auto custom-scroll settings-modal-configs "
                            style={{
                                width: mobile_mode ? `calc(${modalWidth}px - 110px)` : '',
                            }}
                        >
                            {sreen && sreen}
                        </div>
                    </Spin>
                </div>
            </SL_Modal>
        </ConfigProvider>
    )
}
