import { Checkbox, ConfigProvider } from 'antd'
import { useContext } from 'react'
import { colors } from '../../services/style'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { convertSizes } from '../../helpers/helper'

export function SL_Checkbox({ children, pixelsToCalculate = 22, ...rest }) {
    const { windowHeight, windowWidth } = useContext(GeneralContext)
    const sizes = convertSizes(pixelsToCalculate, windowHeight, windowWidth)

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: colors['sl-gray'][150],
                    colorBorder: colors['sl-gray'][50],
                    controlInteractiveSize: sizes.heigth,
                },
            }}
        >
            <Checkbox {...rest}>{children}</Checkbox>
        </ConfigProvider>
    )
}
