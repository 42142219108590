import { useContext, useEffect, useState } from 'react'
import { Form } from 'antd'

import groupIcon from '../../../assets/icons/four_squares.svg'

import { NewOption } from '../components/NewOption'
import { Option } from '../components/Option'
import { NewGroup } from './components/NewGroup'
import { NewTitle } from '../components/NewTitle'
import { CentralsSection } from '../components/CentralsSection'
import { ManageCentrals } from '../components/ManageCentrals'

import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../../enums/EAlertTypes'

import api from '../../../services/api'
import '../gradients.css'

export const GroupOption = ({ onChangeTitle, setShowLoading }) => {
    const [form] = Form.useForm()
    const { showAlert } = useContext(GeneralContext)
    const [isAdding, setIsAdding] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isLooking, setIsLooking] = useState(false)
    const [isManaging, setIsManaging] = useState(false)

    const [groups, setGroups] = useState(null)
    const [group, setGroup] = useState(null)
    const [dataToManage, setDataToManage] = useState(null)

    const actions = {
        ADD: {
            key: 1,
            label: displayMessage('ADD_GROUP'),
        },
        EDIT: {
            key: 2,
            label: displayMessage('EDIT_GROUP'),
        },
        REMOVE: {
            key: 3,
            label: displayMessage('REMOVE_GROUP'),
        },
        SEE: {
            key: 4,
        },
        MANAGE: {
            key: 5,
        },
    }

    const backToInitState = () => {
        setIsAdding(false)
        setIsEditing(false)
        setIsLooking(false)
        setIsManaging(false)
        setGroup(null)
        onChangeTitle(null)
        form.resetFields()
    }

    const handleOnClickAction = (key, group) => {
        if (group) {
            setGroup(group)
            form.setFieldsValue(group)
        }
        let newTitle
        let showButtons = true
        let back = () => backToInitState()
        switch (key) {
            case actions.ADD.key:
                newTitle = displayMessage('ADD_GROUP')
                setIsAdding(true)
                break
            case actions.EDIT.key:
                newTitle = displayMessage('EDIT_GROUP')

                setIsEditing(true)
                break
            case actions.SEE.key:
                newTitle = group.name
                showButtons = false

                setIsLooking(true)
                break
            case actions.MANAGE.key:
                newTitle = displayMessage('CENTRALS_TITLE', [group?.name])
                back = () => {
                    setIsManaging(false)
                    const newKey = isEditing ? actions.EDIT.key : actions.SEE.key
                    handleOnClickAction(newKey, group)
                }
                setIsManaging(true)
                manageCentrals()
                break
        }
        onChangeTitle(<NewTitle title={newTitle} showButtons={showButtons} back={() => back()} form={form} />)
    }

    const createGroup = async (values) => {
        setShowLoading(true)
        try {
            await api.groups.create(values)
            showAlert(displayMessage('SUCCESS_CREATE_GROUP'), EAlertTypes.SUCCESS)
            backToInitState()
            getGroups()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const editGroup = async (values) => {
        setShowLoading(true)
        try {
            await api.groups.update(group.id, values)
            showAlert(displayMessage('SUCCESS_EDIT_GROUP'), EAlertTypes.SUCCESS)
            backToInitState()
            getGroups()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const deleteGroup = async (id) => {
        setShowLoading(true)
        try {
            await api.groups.delete(id)
            showAlert(displayMessage('SUCCESS_DELETE_GROUP'), EAlertTypes.SUCCESS)
            getGroups()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const getGroups = async () => {
        setShowLoading(true)
        try {
            const { data } = await api.groups.list({})
            setGroups(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const manageCentrals = async () => {
        setShowLoading(true)
        try {
            const { data } = await api.groups.prevDataToManageCentrals(group.id)
            setDataToManage(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const saveCentralsManagement = async (values) => {
        setShowLoading(true)
        try {
            const { data } = await api.groups.manage(group.id, values)
            const newGroups = [...groups]
            const newGroup = { ...group }
            const idx = newGroups.findIndex((c) => c.id == group.id)
            newGroups[idx].Centrals = data
            newGroup.Centrals = data

            setGroups(newGroups)
            form.resetFields()
            setDataToManage(null)
            setIsManaging(false)

            const key = isEditing ? actions.EDIT.key : actions.SEE.key
            handleOnClickAction(key, newGroup)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    useEffect(() => {
        getGroups()
    }, [])

    return (
        <>
            {!isAdding && !isEditing && !isLooking && (
                <div className="flex flex-col settings-modal-configs-options">
                    <NewOption onClickAction={() => handleOnClickAction(actions.ADD.key)} message={displayMessage('CLICK_TO_ADD_A_NEW_GROUP')} />

                    {groups &&
                        groups.map((group) => (
                            <Option
                                key={group.key}
                                iconImg={groupIcon}
                                onClickTitle={() => handleOnClickAction(actions.SEE.key, group)}
                                title={group.name}
                                onClickEdition={() => handleOnClickAction(actions.EDIT.key, group)}
                                onConfirmDelete={() => deleteGroup(group.id)}
                                deleteMessage={displayMessage('DELETE_GROUP_CONFIRM_MESSAGE')}
                                editTooltip={actions.EDIT.label}
                                removeTooltip={actions.REMOVE.label}
                            />
                        ))}
                </div>
            )}

            {isAdding && !isManaging && <NewGroup sendValues={createGroup} form={form} isAdding={true} setShowLoading={setShowLoading} />}
            {isEditing && !isManaging && <NewGroup sendValues={editGroup} form={form} />}
            {isLooking && !isManaging && <NewGroup form={form} onlyView={true} />}

            {(isEditing || isLooking) && !isManaging && <CentralsSection centrals={group?.Centrals} onManageCentrals={() => handleOnClickAction(actions.MANAGE.key, group)} />}

            {isManaging && dataToManage && <ManageCentrals data={dataToManage} form={form} onManageCentrals={saveCentralsManagement} />}
        </>
    )
}
