import { useContext, useEffect, useState } from 'react'
import { X } from '@phosphor-icons/react'

import { SL_Modal } from '../../../../components/modal/Modal'
import { Centrals } from '../Centrals'

import { GeneralContext } from '../../../../context/GeneralContext/GeneralContext'
import { getColorByLuminosity } from '../../../../helpers/helper'
import { colors } from '../../../../services/style'

export const GroupModal = ({ centrals, group, onClose, onOpenCentral }) => {
    const initialCentralsToShow = centrals.filter((central) => group.central_ids.includes(central.central_id))
    const { windowHeight, windowWidth, mobile_mode } = useContext(GeneralContext)
    const [centralsToShow, setCentralsToShow] = useState(initialCentralsToShow)
    const [justActiveAlerts, setJustActiveAlerts] = useState(false)

    const title = (
        <div
            className="w-full text-white rounded-t-md text-center group-modal-title-div flex justify-between items-center px-4"
            style={{ backgroundColor: initialCentralsToShow?.[0]?.color, color: getColorByLuminosity(initialCentralsToShow?.[0]?.color) }}
        >
            <span className="text-medium group-modal-title flex-1 text-center">{group.group_name}</span>
            <X color={colors['sl-gray'][50]} className="cursor-pointer settings-modal-configs-icon" onClick={onClose} />
        </div>
    )

    useEffect(() => {
        if (justActiveAlerts) {
            setCentralsToShow(initialCentralsToShow.filter((central) => central.has_alerts))
        } else {
            setCentralsToShow(initialCentralsToShow)
        }
    }, [justActiveAlerts])
    return (
        <SL_Modal
            open={true}
            closable={false}
            closeIcon={null}
            footer={null}
            width={windowWidth * 0.9}
            title={title}
            onCancel={onClose}
            bodyStyle={{
                padding: 12,
                heigth: windowHeight * 0.7,
                overflow: 'auto',
            }}
        >
            <Centrals centrals={centralsToShow} onOpenCentral={onOpenCentral} onFilterByAlerts={setJustActiveAlerts} justActiveAlerts={justActiveAlerts} onChangeVisibility={null} fromGroup={true} />
        </SL_Modal>
    )
}
