import { useContext } from 'react'
import plusIcon from '../../../assets/icons/plus.svg'

import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { colors } from '../../../services/style'

export const NewOption = ({ onClickAction, message }) => {
    const { mobile_mode } = useContext(GeneralContext)
    return (
        <div
            className="w-full flex justify-center items-center  cursor-pointer p-3 rounded settings-modal-configs-new-option"
            style={{ border: `dashed 2px ${colors['sl-gray'][300]}` }}
            onClick={() => onClickAction()}
        >
            <span className="text-white settings-modal-configs-option-label font-medium uppercase">{message}</span>
            {!mobile_mode && (
                <div className="settings-modal-configs-option-icon-div bg-sl-gray-100 flex justify-center items-center">
                    <img src={plusIcon} className="settings-modal-configs-option-icon" />
                </div>
            )}
        </div>
    )
}
