import { useEffect, useState } from 'react'
import { Form } from 'antd'

import { FormItem } from '../../components/FormItem'
import { SL_Input } from '../../../../components/input/Input'
import { ManageCentrals } from '../../components/ManageCentrals'
import { FormSubtitle } from '../../components/FormSubtitle'

import { displayMessage } from '../../../../helpers/messages'
import { CnpjMaskedInput } from '../../../../helpers/iMask'
import api from '../../../../services/api'

import '../../gradients.css'

export const NewCompany = ({ sendValues, form, onlyView, isAdding, setShowLoading }) => {
    const [dataToManage, setDataToManage] = useState(null)

    const manageCentrals = async () => {
        setShowLoading(true)
        try {
            const { data } = await api.centrals.prevDataToManageCentrals(null)
            setDataToManage(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    useEffect(() => {
        if (isAdding) manageCentrals()
    }, [isAdding])
    return (
        <Form disabled={onlyView} onFinish={sendValues} className="w-full flex flex-col settings-modal-configs-form-divisions" layout="vertical" requiredMark={false} form={form}>
            <div className="flex flex-col settings-modal-configs-form">
                <FormSubtitle message={displayMessage('IDENTIFICATION')} />
                <FormItem className={'flex flex-col w-full'} name="name" label={displayMessage('FIRST_NAME')}>
                    <SL_Input placeholder={displayMessage('FIRST_NAME')} className={'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white'} />
                </FormItem>

                <div className="flex gap-[30px] w-full">
                    <FormItem className={`flex flex-col w-full`} name="cnpj" label={displayMessage('CNPJ')}>
                        {CnpjMaskedInput({
                            placeholder: displayMessage('CNPJ'),
                            className: 'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white',
                            disabled: onlyView,
                            onChange: (e) => {
                                form.setFieldValue('cnpj', e.unmaskedValue)
                            },
                        })}
                    </FormItem>

                    <FormItem className={`flex flex-col w-full`} name="user_limit" label={displayMessage('USER_LIMIT')}>
                        <SL_Input placeholder={displayMessage('USER_LIMIT')} className={'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white'} type="number" />
                    </FormItem>
                </div>
            </div>

            {isAdding && dataToManage && <ManageCentrals data={dataToManage} form={form} onManageCentrals={(e) => sendValues(e)} isAdding={true} />}
        </Form>
    )
}
