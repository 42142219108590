import { useContext } from 'react'
import { ConfigProvider } from 'antd'

import backIcon from '../../../assets/icons/ArrowLeft.svg'

import { SL_Button } from '../../../components/buttons/Button'

import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

export const NewTitle = ({ title, showButtons, back, form }) => {
    const { mobile_mode } = useContext(GeneralContext)

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        lineWidth: 0,
                        primaryShadow: '0 0 0',
                    },
                },
            }}
        >
            <div className={`flex justify-between items-center w-full flex-wrap}`}>
                <div className={`flex gap-3 items-center justify-center`}>
                    <img src={backIcon} className="settings-modal-configs-title-back-icon cursor-pointer" onClick={() => back()} />
                    <span className="text-white mx-2 settings-modal-configs-title">{title}</span>
                </div>
                {showButtons && (
                    <div className="flex gap-3 items-start p-1">
                        <SL_Button
                            type="default"
                            className="bg-white/10 hover:bg-white/20 text-white rounded settings-modal-configs-title-button flex justify-center items-center font-medium"
                            onClick={() => back()}
                        >
                            <span className="form-label">{displayMessage('CANCEL')}</span>
                        </SL_Button>
                        <SL_Button
                            type="default"
                            className="bg-primary-600 hover:bg-primary-600/80 text-white rounded settings-modal-configs-title-button px-4 flex justify-center items-center font-medium"
                            onClick={() => form.submit()}
                        >
                            <span className="form-label">{displayMessage('SAVE')}</span>
                        </SL_Button>
                    </div>
                )}
            </div>
        </ConfigProvider>
    )
}
