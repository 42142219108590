import { useContext, useEffect, useRef, useState } from 'react'
import { Form } from 'antd'

import plusIcon from '../../../../assets/icons/plus.svg'
import userIcon from '../../../../assets/icons/user.svg'
import trashIcon from '../../../../assets/icons/trash.svg'

import { SL_Input } from '../../../../components/input/Input'
import { SL_Button } from '../../../../components/buttons/Button'
import { SL_Confirm } from '../../../../components/confirm/Confirm'
import { FormItem } from '../../components/FormItem'
import { MultiSelect } from '../../../../components/select/MultiSelect'
import { FormSubtitle } from '../../components/FormSubtitle'
import { Option } from '../../notifications/components/Option'

import { displayMessage } from '../../../../helpers/messages'

import '../../gradients.css'
import { colors } from '../../../../services/style'
import { CnpjMaskedInput } from '../../../../helpers/iMask'
import api from '../../../../services/api'

export const NewCentral = ({ sendValues, form, onlyView, alowVinculateUsers = false, users = [], handleNewUser, handleDeleteUser }) => {
    const [company, setCompany] = useState(null)
    const [emailsToInform, setEmailsToInform] = useState([])
    const [newEmail, setNewEmail] = useState(null)

    const onFinish = (values) => {
        values.emergency_contacts = emailsToInform
        sendValues(values)
    }

    const onAddEmail = () => {
        if (!emailsToInform.includes(newEmail)) {
            setEmailsToInform((current) => [...current, newEmail])
        }
        setNewEmail(null)
    }

    const onRemoveEmail = (emailInformed) => {
        setEmailsToInform(emailsToInform.filter((email) => email != emailInformed))
    }

    const mountUsersOptions = async (name) => {
        try {
            const { data } = await api.users.list({
                not_in: users.map((user) => user.id),
                name,
            })

            return data.map((user) => ({
                value: user.id,
                label: user.full_name,
            }))
        } catch (error) {
            console.log(error)
        }
    }

    const mountCompaniesOptions = async (name) => {
        try {
            const { data } = await api.companies.list({
                name,
            })

            return data.map((company) => ({
                value: company.id,
                label: company.name,
            }))
        } catch (error) {
            console.log(error)
        }
    }

    const handleNewCompany = (e) => {
        form.setFieldValue('company_id', e.value)
    }

    useEffect(() => {
        setCompany(form?.getFieldValue('Company'))
        console.log(form?.getFieldValue('Company'))
        if (form?.getFieldValue('emergency_contacts')) setEmailsToInform(form?.getFieldValue('emergency_contacts'))
    }, [form])

    return (
        <Form disabled={onlyView} onFinish={onFinish} className="w-full flex flex-col settings-modal-configs-form-divisions" layout="vertical" requiredMark={false} form={form} autoComplete="dontshow">
            <div className="flex flex-col settings-modal-configs-form">
                <FormSubtitle message={displayMessage('IDENTIFICATION')} />
                {!company && (
                    <FormItem className={'flex flex-col w-full'} name="company_id" label={displayMessage('INTEGRATOR')} required={false}>
                        <MultiSelect
                            autocomplete="none"
                            className="w-full font-bold shadow-md bg-black  text-white settings-modal-configs-custom-multiselect-height"
                            mode="single"
                            fetchOptions={async (e) => mountCompaniesOptions(e)}
                            onChange={handleNewCompany}
                            placeholder={displayMessage('SEARCH_FOR_INTEGRATOR')}
                        />
                    </FormItem>
                )}

                {company && (
                    <div className={`flex flex-col w-full settings-modal-configs-form-item`}>
                        <div className="opacity-60 form-label">{displayMessage('IDENTIFICATION')}</div>

                        <MultiSelect
                            className="w-full font-bold shadow-md bg-black  text-white settings-modal-configs-custom-select-height"
                            mode="single"
                            fetchOptions={async (e) => mountCompaniesOptions(e)}
                            onChange={handleNewCompany}
                            placeholder={displayMessage('SEARCH_FOR_INTEGRATOR')}
                            defaultValue={company?.name}
                        />
                    </div>
                )}
                <FormItem className={'flex flex-col w-full'} name="name" label={displayMessage('CENTRAL_NAME')}>
                    <SL_Input placeholder={displayMessage('CENTRAL_NAME')} className={'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white'} />
                </FormItem>
                <FormItem className={'flex flex-col w-full'} name="client" label={displayMessage('CLIENT')}>
                    <SL_Input placeholder={displayMessage('CLIENT')} className={'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white'} />
                </FormItem>
                <div className="flex gap-[30px]">
                    <FormItem className={'flex flex-col w-full'} name="city" label={displayMessage('CITY')}>
                        <SL_Input placeholder={displayMessage('CITY')} className={'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white'} />
                    </FormItem>
                    <FormItem className={'flex flex-col w-full'} name="state" label={displayMessage('STATE')}>
                        <SL_Input placeholder={displayMessage('STATE')} className={'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white'} />
                    </FormItem>
                </div>
            </div>

            <div className="flex flex-col settings-modal-configs-form">
                <FormSubtitle message={displayMessage('TEAM_VIEWER_ACESS')} />

                <div className="flex gap-[30px]">
                    <FormItem className={'flex flex-col w-full'} name="team_viewer_id" label={displayMessage('TEAM_VIEWER_ID_WITH_INFO')}>
                        <SL_Input placeholder={displayMessage('TEAM_VIEWER_ID')} className={'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white'} />
                    </FormItem>
                    <FormItem className={'flex flex-col w-full'} name="team_viewer_password" label={displayMessage('PASSWORD')}>
                        <SL_Input placeholder={displayMessage('PASSWORD')} className={'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white'} type="password" />
                    </FormItem>
                </div>
            </div>

            <div className="flex flex-col settings-modal-configs-form">
                <FormSubtitle message={displayMessage('PREFERENCES')} />

                <Option
                    title={displayMessage('USER_ACTIONS')}
                    description={displayMessage('PERMIT_USER_ACTIONS')}
                    onChange={() => {}}
                    checked={![null, undefined].includes(form.getFieldValue('allow_user_actions')) ? form.getFieldValue('allow_user_actions') : false}
                    formName="allow_user_actions"
                />
            </div>

            {alowVinculateUsers && (
                <div className="flex flex-col settings-modal-configs-form">
                    <FormSubtitle message={displayMessage('USERS_MANAGEMENT')} />
                    <div className="flex gap-4 justify-between">
                        <MultiSelect
                            className="w-full font-bold shadow-md bg-black settings-modal-configs-custom-multiselect-height text-white"
                            mode="single"
                            fetchOptions={async (e) => mountUsersOptions(e)}
                            onChange={handleNewUser}
                            placeholder={displayMessage('SEARCH_FOR_USER')}
                            value={null}
                        />
                    </div>

                    <div className="flex flex-col gap-[5px]">
                        {users.map((user) => (
                            <div key={user.id} id={`user-${user.id}`} className="settings-modal-configs-option rounded p-4  bg-sl-transparency-8 flex items-center justify-between">
                                <div className="gap-[15px] flex items-center">
                                    <img src={userIcon} className="settings-modal-configs-option-icon" />
                                    <span className="settings-modal-configs-option-label">{user.full_name}</span>
                                </div>
                                <SL_Confirm
                                    title={displayMessage('REMOVE_ACESS_FROM_USER_TO_CENTRAL_CONFIRM_MESSAGE')}
                                    onConfirm={() => handleDeleteUser(user.id)}
                                    okText={displayMessage('REMOVE_ACESS')}
                                    placement="bottom"
                                    tooltipTitle={displayMessage('REMOVE_ACESS_FROM_USER_TO_CENTRAL')}
                                >
                                    {' '}
                                    <SL_Button
                                        type="default"
                                        className="text-white rounded p-1 flex justify-center items-center bg-transparent gap-1 settings-modal-configs-option-user-button "
                                        style={{ border: `1px solid ${colors['sl-gray'][150]}` }}
                                    >
                                        <img src={trashIcon} className="settings-modal-configs-option-user-icon" />
                                        <span className="opacity-50 settings-modal-configs-option-user-label text-medium">{displayMessage('DELETE')}</span>
                                    </SL_Button>
                                </SL_Confirm>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className="flex flex-col settings-modal-configs-form">
                <FormSubtitle message={displayMessage('EMERGENCY_CONTACTS')} />
                <div className="flex gap-4 justify-between">
                    <SL_Input
                        placeholder={displayMessage('ADD_CONTACT')}
                        className={'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white'}
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                    />

                    <SL_Button
                        type="default"
                        className="bg-primary-600 hover:bg-primary-600/80 text-white settings-modal-configs-plus-button rounded py-[10px] px-[15px] flex justify-center items-center"
                        onClick={() => onAddEmail()}
                    >
                        <img src={plusIcon} className="settings-modal-configs-option-icon" />
                    </SL_Button>
                </div>

                {emailsToInform.length ? (
                    <div className="flex flex-col gap-[5px]">
                        {emailsToInform.map((email) => (
                            <div key={email} id={email} className="settings-modal-configs-option rounded p-4  bg-sl-transparency-8 flex items-center justify-between">
                                <div className="gap-[15px] flex items-center">
                                    <img src={userIcon} className="settings-modal-configs-option-icon" />
                                    <span className="settings-modal-configs-option-label">{email}</span>
                                </div>
                                <SL_Button
                                    type="default"
                                    className="text-white rounded py-1 px-3 flex justify-center items-center bg-transparent gap-1 settings-modal-configs-option-user-button "
                                    style={{ border: `1px solid ${colors['sl-gray'][150]}` }}
                                    onClick={() => onRemoveEmail(email)}
                                >
                                    <img src={trashIcon} className="settings-modal-configs-option-user-icon" />
                                    <span className="opacity-50 settings-modal-configs-option-user-label  text-medium">{displayMessage('DELETE')}</span>
                                </SL_Button>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>

            {company && (
                <div className="flex flex-col settings-modal-configs-form">
                    <span className="info-label">{displayMessage('VINCULATED_COMPANY')}</span>
                    <FormItem className={'flex flex-col w-full'} name="company_name" label={displayMessage('FIRST_NAME')}>
                        <SL_Input
                            placeholder={displayMessage('FIRST_NAME')}
                            className={'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white'}
                            defaultValue={company.name}
                            disabled
                        />
                    </FormItem>

                    <div className="flex gap-[30px] w-full">
                        <FormItem className={`flex flex-col w-full`} name="company_cnpj" label={displayMessage('CNPJ')}>
                            {CnpjMaskedInput({
                                placeholder: displayMessage('CNPJ'),
                                className: 'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white',
                                disabled: true,
                                defaultValue: company.cnpj,
                            })}
                        </FormItem>

                        <FormItem className={`flex flex-col w-full`} name="company_user_limit" label={displayMessage('USER_LIMIT')}>
                            <SL_Input
                                placeholder={displayMessage('USER_LIMIT')}
                                className={'w-full font-bold shadow-md bg-black settings-modal-configs-custom-input-height text-white'}
                                type="number"
                                disabled
                                defaultValue={company.user_limit}
                            />
                        </FormItem>
                    </div>
                </div>
            )}
        </Form>
    )
}
