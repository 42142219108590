import { useContext } from 'react'
import { Form } from 'antd'

import centralsIcon from '../../../assets/icons/central.svg'

import { SL_Checkbox } from '../../../components/checkbox/Checkbox'

import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

export const CentralOnCentralSection = ({ central, checkbox, checkedCentrals, onChangeCheckbox }) => {
    const { mobile_mode } = useContext(GeneralContext)
    const getLocation = ({ city, state }) => {
        switch (true) {
            case city && state:
                return `${city} - ${state}`
            case !city && !state:
                return displayMessage('NOT_INFORMED_LOCATION')
            default:
                return `${city ?? displayMessage('EMPTY')} - ${state ?? displayMessage('EMPTY')}`
        }
    }

    return (
        <div
            className={`${checkbox ? 'relative' : ''} rounded settings-modal-configs-central-section-central  bg-sl-transparency-8 flex flex-col ${
                mobile_mode ? 'w-full justify-center items-center' : 'justify-between'
            }`}
        >
            {checkbox && (
                <Form.Item name={central.id} className="m-0 settings-modal-configs-central-section-central-absolute">
                    <SL_Checkbox className="" checked={checkedCentrals.includes(central.id)} onChange={(e) => onChangeCheckbox(central.id, e.target.checked)} />
                </Form.Item>
            )}
            <div className="flex flex-col settings-modal-configs-central-section-central-gap">
                <div className="bg-sl-gray-150 settings-modal-configs-central-section-central-icon-div rounded-lg flex justify-center items-center">
                    <img src={centralsIcon} className="settings-modal-configs-central-section-central-icon" />
                </div>

                <span className="text-white text-medium settings-modal-configs-central-section-central-name uppercase line-clamp-2">{central.name}</span>
            </div>
            <div className="flex flex-col settings-modal-configs-central-section-central-location-div opacity-40">
                <span className="uppercase settings-modal-configs-central-section-central-location line-clamp-2">{getLocation(central)}</span>
                <span className="uppercase settings-modal-configs-central-section-central-location">
                    {displayMessage(central.devices_count > 1 ? 'DEVICES_COUNT' : 'DEVICES_COUNT_S', [central.devices_count])}
                </span>
            </div>
        </div>
    )
}
