import { useEffect, useState } from 'react'
import { colors } from '../../../services/style'
import { getColorByLuminosity } from '../../../helpers/helper'
import { Central } from './Central'

export const Group = ({ data, onOpenGroup, centrals }) => {
    const color = data?.color || colors['sl-gray'][100]
    const [groupCentrals, setGroupCentrals] = useState([])

    const formatCentrals = () => {
        const group_centrals = centrals.filter((central) => data?.central_ids?.includes(central.central_id))
        setGroupCentrals(group_centrals)
    }

    useEffect(() => {
        formatCentrals()
    }, [centrals])

    return (
        <div
            className="central-group rounded-lg relative gradient-button cursor-pointer bg-sl-gray-100"
            onClick={() => onOpenGroup(data)}
            // se descomentar esse estilo a classe gradient-button deve ser substituida por gradient-button-custom
            // style={{ backgroundColor: color, color: getColorByLuminosity(color) }}
        >
            <div className="w-full rounded-t-lg flex items-center justify-center group-title">
                <span className="truncate font-medium">{data.group_name}</span>
            </div>
            <div className={`flex flex-wrap group-centrals overflow-hidden`}>
                {groupCentrals.map((central, i) => (
                    <Central index={i} key={'litle_central_id_' + central.central_id} data={central} onOpenCentral={() => {}} littleCentral={true} />
                ))}
            </div>
        </div>
    )
}
