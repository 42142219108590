export default {
    /* GLOBAL SONIC */
    TEST: 'Teste',
    ATENTION: 'Atenção',
    FAIL_LIST_USERS_BY_CENTRAL: 'Não foi possível listar os usuários desta central',
    NO_DEVICES: 'Não há dispositivos a serem exibidos',
    ALL: 'Todos',
    SYSTEM_ACTIONS: 'Ações do sistema',
    CENTRALS: 'Centrais',
    IP: 'IP: {0}',
    CHANGE_CENTRAL: 'Selecione a central ativa',
    ACTIVE_CENTRAL: 'Central ativa: {0}',
    PENDING_NOTIFICATIONS: 'Notificações pendentes',
    ACTIVATE: 'Ativado',
    DEACTIVATE: 'Desativado',

    /* General */
    SELECT_ACTIONS: 'Selecionar ações',
    WRITE_MORE_DETAILS: 'Escreva mais detalhes...',

    /* Login */
    LOGIN: 'Login',
    LOGIN_FAIL: 'Falha de login',
    USER_NOT_REGISTER: 'Usuário não cadastrado no sistema.',
    ENTER: 'Entrar',
    EMAIL: 'E-mail',
    PASSWORD: 'Senha',
    CONFIRM_PASSWORD: 'Confirmar senha',

    /* Centrals */
    ATTENTION: 'Atenção',
    FAIL_LOAD_CENTRALS: 'Falha ao buscar centrais.',
    FAIL_UPDATE_CENTRALS: 'Falha ao atualizar central ativa.',
    FAIL_LOAD_CURRENT_CENTRAL: 'Falha ao buscar dados da central ativa.',

    /* Alerts */
    FAIL_LOAD_ALERTS: 'Falha ao buscar alertas',
    NO_DEVICES: 'Não há dispositivos a serem exibidos',

    /* UsersList */
    USERS_BY_CENTRAL: 'Usuários vinculados: {0}',

    /* UserCard */
    CREATED_AT: 'Criado: {0}',
    NAME: 'Nome: {0}',
    USER_ADMIN: 'Administrador',
    DEACTIVE_USER: 'Desativado',
    ACTIVE_USER: 'Ativado',
    ERROR_UPDATE_USER_STATUS: 'Problemas ao atualizar status de usuário',

    /* ModalCOnfirmUserDeactivation */
    CONFIRM: 'Confirmar',
    CANCEL: 'Cancelar',
    CONFIRM_DEACTIVATE_USER_MSG: 'Desativar usuário?',

    /* CreateUser */
    CREATE_USER: 'Criação de Usuário',
    CREATE_USER_FORM_DESCRIPTION: 'Para criar um novo usuário preencha o formulário com todas informações abaixo. ',
    USER_CREATED: 'Usuário criado com sucesso',
    FIRST_NAME: 'Nome',
    LAST_NAME: 'Sobrenome',
    EMAIL: 'Email',
    PASSWORD: 'Senha',
    CONFIRM_PASSWORD: 'Confirme sua senha',
    CREATE: 'Criar',
    GO_BACK: 'Voltar',
    ERROR_CREATE_USER: 'Problemas ao criar usuário!',

    /* Reports */
    ZONE: 'Zona {0}',
    ZONE_TITLE: 'Zona',
    USER_ACTIONS: 'Ações do usuário',
    USER_ACTIONS_TITLE: 'Relatório de Ações do usuário',

    BATTERY: 'Nível de bateria',
    BATTERY_TITLE: 'Relatório de nível de bateria',
    BATTERY_VOLTS: 'Voltagem de bateria',
    BATTERY_TITLE: 'Relatório de nível de baterias',
    BATTERY_LEVEL: '{0} | 2.88V',

    ALERT_LOGS: 'Log de alertas',
    ALERT_LOGS_TITLE: 'Relatório log de alertas',

    ALERT_HANDLING: 'Tratativa de alertas',
    ALERT_HANDLING_TITLE: 'Relatório de tratativas dos alertas',

    ACTIVE_ALERT: 'Alertas ativos',
    ACTIVE_ALERT_TITLE: 'Relatório de alertas ativos',

    FILTERS: 'Filtros',
    NO_REPORTS: 'Não há relatórios',
    CSV_EXPORT: 'Exportar CSV',
    DATE_AND_HOUR: 'Data e Hora',
    SHOW_MORE: 'Mostrar mais',
    DEVICE_FIRST_NAME: 'Nome inicial do dispositivo',
    DEVICE_LAST_NAME: 'Nome final do dispositivo',

    /* ReportErrors */
    ERROR_LOADING_USER_ACTIONS_REPORTS: 'Problemas ao listar relatório de ações do usuário!',
    ERROR_LOADING_ALERT_LOGS_REPORTS: 'Problemas ao listar relatório de log de alertas!',
    ERROR_LOADING_ALERT_HANDLING_REPORTS: 'Problemas ao listar relatório de tratativa de alertas!',
    ERROR_READING_NOTIFICATIONS: 'Problemas ao ler notificações!',
    COMMUNICATION_CENTRAL_FAIL: 'Falha na comunicação com a central.',

    /* Menu */
    MY_PROFILE: 'Meu perfil',
    USERS: 'Usuários',
    USER: 'Usuário',
    LOGOUT: 'Logout',

    /* Status */
    FIRE: 'Fogo',
    ALERT: 'Alerta',
    DISABLE: 'Desabilitado',
    EXIT_FAIL: 'Falha saída',
    CENTRAL_FAIL: 'Falha central',
    SUPERVISION: 'Supervisão',
    FAIL: 'Falha',
    DISABLE: 'Desabilitado',
    CENTRAL_FAIL: 'Falha central',
    STATUS: 'Status',

    /* Home */
    GENERAL_ALERT: 'Alerta Geral',
    RESET: 'Reset',
    SILENCE: 'Silenciar',
    GROUP_ALERT: 'Alerta de Grupo',

    CONFIRM_ACTION: 'Você confirma a operação?',
    NO_ACTIVATION_GROUPS: 'Nenhum grupo de ativação disponivel',
    FAIL_LOAD_SYSTEM_ACTION_BUTTONS: 'Falha ao buscar dados, botões acções do sistema. ',

    /* ReportsList */
    SEARCH: 'Pesquisar',
    DEVICE_NAME: 'Nome do Dispositivo',
    SOLUTION: 'Tratativa',
    SOLUTIONS: 'Tratativas',
    DESCRIPTION: 'Descrição',
    DEVICE_CENTRAL_GROUP: 'Dispositivo, Central ou Grupo',
    FAIL_LOAD_SYSTEM_ACTION_BUTTONS: 'Falha ao buscar dados, botões ações do sistema. ',
    RESET_DEVICES_RECEIVED: 'Sinal de reset de dispositivos recebido pela central',
    SILENCE_DEVICES_RECEIVED: 'Sinal para silenciar dispositivos recebido pela central',
    ALERT_GROUP_RECEIVED: 'Sinal de alerta de grupo recebido pela central',
    ALARM_MONITOR: 'Monitor de alarmes',
    GLOBAL_SONIC: 'Global Sonic',
    FORGOT_PASSWORD: 'Esqueci minha senha',
    SEND: 'Enviar',
    RESEND: 'Reenviar',
    INFO_FORGOT_PASSWORD: 'Para redefinir sua senha, informe seu endereço de email que enviaremos as instruções de acesso.',
    CHANGE_PASSWORD: 'Redefinir senha',
    SUCCESS_SEND_EMAIL: 'Email enviado com sucesso.',

    ERROR: 'Erro',
    SUCCESS: 'Sucesso',
    WARNING: 'Atenção',

    GROUPS: 'Grupos',
    NOTIFICATIONS: 'Notificações',
    REPORTS: 'Relatórios',
    IP: 'IP',

    /* Users */
    ADD_USER: 'Adicionar Usuário',
    EDIT_USER: 'Editar Usuário',
    REMOVE_USER: 'Excluir Usuário',
    SAVE: 'Salvar',
    IDENTIFICATION: 'Identificação',
    CONTACT: 'Contato',
    EMERGENCY_CONTACT: 'Contato de emergência',
    ACESS: 'Acesso',
    PAINEL_ACCESS: 'Acesso ao painel',
    APP_ACCESS: 'Acesso ao app',
    TYPE: 'Tipo',
    MANAGE_USERS: 'Gerir usuários',
    USERS_MANAGEMENT: 'Gerenciamento de usuários',
    REMOVE_ACESS: 'Remover acesso',
    REMOVE_ACESS_FROM_USER_TO_CENTRAL: 'Remover o acesso deste usuário a esta central',
    REMOVE_ACESS_FROM_USER_TO_CENTRAL_CONFIRM_MESSAGE: 'Tem certeza que deseja remover o acesso deste usuário a esta central?',
    SUCCESS_DELETE_ACESS_FROM_USER_TO_CENTRAL: 'Acesso removido com sucesso',
    SUCCESS_ADD_ACESS_FROM_USER_TO_CENTRAL: 'Acesso acidionado com sucesso',
    SEARCH_FOR_USER: 'Pesquise pelo novo usuário',
    SEARCH_FOR_INTEGRATOR: 'Pesquise pelo novo integrador',
    ACCESS_LEVEL: 'Nível de acesso',

    ADM_GLOBAL: 'Administrador Painel',
    MONITOR_GLOBAL: 'Monitor Painel',
    INTEGRATOR: 'Integrador',
    ADM_APP: 'Administrador App',
    MONITOR_APP: 'Monitor App',
    REQUIRED_FIELD: 'Campo obrigatório',
    SUCCESS_CREATE_USER: 'Usuário criado com sucesso',
    CLICK_TO_ADD_A_NEW_USER: 'Clique para adicionar um novo usuário',
    DELET_CONFIRM_MESSAGE: 'Tem certeza que deseja deletar esse usuário?',
    SUCCESS_DELETE_USER: 'Usuário excluído com sucesso',
    SUCCESS_EDIT_USER: 'Usuário editado com sucesso',

    /* Companies */
    COMPANIES: 'Empresas',
    ADD_COMPANY: 'Adicionar Empresa',
    EDIT_COMPANY: 'Editar Empresa',
    REMOVE_COMPANY: 'Excluir Empresa',
    CLICK_TO_ADD_A_NEW_COMPANY: 'Clique para adicionar uma nova empresa',
    DELETE_COMPANY_CONFIRM_MESSAGE: 'Tem certeza que deseja deletar essa empresa?',
    SUCCESS_CREATE_COMPANY: 'Empresa criada com sucesso',
    SUCCESS_DELETE_COMPANY: 'Empresa excluída com sucesso',
    SUCCESS_EDIT_COMPANY: 'Empresa editada com sucesso',
    CNPJ: 'CNPJ',
    USER_LIMIT: 'Limite de usuários',
    VINCULATED_COMPANY: 'Empresa Vinculada',

    /* Centrals */
    CENTRALS: 'Centrais',
    CENTRAL: 'Central',
    DEVICES_COUNT: '{0} Dispositivos',
    DEVICES_COUNT_S: '{0} Dispositivo',
    MANAGE_CENTRALS: 'Gerir Centrais',
    CHOSE_CENTRAL: 'Escolha uma central para gerar o relatório',
    VINCULATED_CENTRALS: 'Centrais Vinculadas',
    NOT_VINCULATED_CENTRALS: 'Centrais Não Vinculadas',
    TO_VINCULATE: 'Vincular',
    TO_DISASSOCIATE: 'Desvincular',
    ADD_CENTRAL: 'Adicionar Central',
    EDIT_CENTRAL: 'Editar Central',
    REMOVE_CENTRAL: 'Excluir Central',
    CLICK_TO_ADD_A_NEW_CENTRAL: 'Clique para adicionar uma nova central',
    DELETE_CENTRAL_CONFIRM_MESSAGE: 'Tem certeza que deseja deletar essa central?',
    SUCCESS_CREATE_CENTRAL: 'Central criada com sucesso',
    SUCCESS_DELETE_CENTRAL: 'Central excluída com sucesso',
    SUCCESS_EDIT_CENTRAL: 'Central editada com sucesso',
    CENTRAL_NAME: 'Nome da central',
    CLIENT: 'Cliente',
    CITY: 'Cidade',
    STATE: 'Estado',
    TEAM_VIEWER_ACESS: 'Acesso Team Viewer',
    TEAM_VIEWER_ID: 'Team Viewer ID',
    TEAM_VIEWER_ID_WITH_INFO: 'Team Viewer ID (10 digitos numéricos)',
    PERMIT_USER_ACTIONS: 'Permitir que o usuário performe ações dentro dessa central.',
    CONECTION_IP: 'IP da Conexão',
    CONECTION: 'Conexão',
    VERIFY_CONECTION: 'Verificar Conexão',
    GS_ID: 'Código Global Solnic',
    NOTIFICATION_INTERVAL: 'Intervalo de notificações',
    TIME: 'Tempo',
    MINUTES: 'Minutos',
    HOURS: 'Horas',
    SECONDS: 'Segundos',
    EMERGENCY_CONTACTS: 'Contatos de emergência',
    EMAILS_TO_INFORM_PLACEHOLDER: "Separe múltiplos e-mails com ';'",
    PREFERENCES: 'Preferências',
    ADD_CONTACT: 'Adicionar contato',
    DELETE: 'Deletar',
    INTEGRATOR: 'Integrador',
    NOT_INFORMED_LOCATION: 'Localização não informada',
    PERMISSIONS: 'Permissões',
    CENTRALS_TITLE: 'Centrais - {0}',

    CHANGE_CLIENT: 'Mudar CLiente',

    /* Monitoring */
    LAST_ALERTS: 'Últimos alertas',
    TODAY: 'Hoje',
    LAST_WEEK: 'Última semana',
    LAST_TWO_WEEKS: 'Últimos 15 dias',
    LAST_MONTH: 'Último mês',
    OFFLINE: 'Offline',
    NO_ALERTS_ON_PERIOD: 'Sem alertas no período',
    LOCATION: 'Local: {0}',
    LOCATION_TITLE: 'Local',
    EMPTY: 'N/A',
    NO_DEVICES: 'Não há dispositivos a serem exibidos',
    NO_ACTIVATION_GROUPS: 'Nenhum grupo de ativação disponivel',
    ERROR_TRY_CHANGE_ACTIVE_GROUPS: 'Não é possível selecionar Grupos com status diferentes. Você só pode ativar múltiplos grupos OU desativar múltiplos grupos',
    FOCUS_VEWER: 'Visualização Foco',
    NO_ALERT_CENTRALS: 'Nenhuma central em estado de alerta ou falha.',
    SEE_NOW: 'Ver agora',
    OPEN_CENTRAL: 'Abrir central',
    CREATE_MONITORING_ALERT: 'Criar registro de monitoramento',
    ACTIONS_REPORT: 'Relatório de Ações',
    SEND_REPORT_TO_EMAIL: 'Envie seu relatório por email',
    SAVED_REPORT: 'Relatório Salvo',
    ADD_EMAIL: 'Adicionar email',
    DONT_SEND: 'Não enviar',
    SEND_EMAILS: 'Enviar Emails',
    SUCCESS_ON_SEND_EMAILS: 'Emails enviados com sucesso',
    SUCCESS_UPDATE_CENTRALS_DATA: 'Atualização de dados identificada',

    /* GROUPS */
    ADD_GROUP: 'Adicionar Grupo',
    EDIT_GROUP: 'Editar Grupo',
    REMOVE_GROUP: 'Excluir Grupo',
    CLICK_TO_ADD_A_NEW_GROUP: 'Clique para adicionar um novo grupo',
    DELETE_GROUP_CONFIRM_MESSAGE: 'Tem certeza que deseja deletar essa grupo?',
    SUCCESS_CREATE_GROUP: 'Grupo criado com sucesso',
    SUCCESS_DELETE_GROUP: 'Grupo excluído com sucesso',
    SUCCESS_EDIT_GROUP: 'Grupo editado com sucesso',
    SEARCH: 'Busca',
    TO_SEARCH_CENTRALS: 'Pesquisar Centrais',
    RESULTS: 'Resultados',
    ADD_ALL: 'Adicionar todos',
    REMOVE_ALL: 'Remover todos',

    /* NOTIFICATIONS */
    REPORT: 'Relatório',
    REPORT_DESCRIPTION: 'Seja notificado após cada alerta ou falha.',
    POPUP_REPORT: 'Relatório pop-up',
    POPUP_REPORT_DESCRIPTION: 'Após finalizar um alerta ou falha, um pop-up aparecerá no canto superior direito com um campo para digitar seu relatório.',
    SEND_REPOR_BY_EMAIL: 'Enviar relatório por email',
    SEND_REPOR_BY_EMAIL_DESCRIPTION: 'Mostrar emails para enviar após finalizar um relatório de alerta ou falha.',
    SYSTEM_ALERTS: 'Alertas do sistema',
    SYSTEM_ALERTS_DESCRIPTION: 'Notificações sobre o status do sistema.',

    /* SOUNDS */
    ENABLE_SOUND_NOTIFICATIONS: 'Deseja habilitar notificações sonoras?',
    YES: 'SIM',
    NO: 'NÃO',
}
