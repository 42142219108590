import { useEffect, useState } from 'react'
import { Form } from 'antd'

import { SL_Input } from '../../../../components/input/Input'
import { GS_Select } from '../../../../components/select/GS_Select'
import { FormItem } from '../../components/FormItem'
import { ManageCentrals } from '../../components/ManageCentrals'
import { FormSubtitle } from '../../components/FormSubtitle'

import { displayMessage } from '../../../../helpers/messages'
import { colors } from '../../../../services/style'

import '../../gradients.css'
import api from '../../../../services/api'
import EUserTypes from '../../../../enums/EUserTypes'

export const NewUser = ({ sendValues, form, onlyView, isAdding, setShowLoading }) => {
    const onFinish = (values) => {
        sendValues(values)
    }

    const [dataToManage, setDataToManage] = useState(null)

    const manageCentrals = async () => {
        setShowLoading(true)
        try {
            const { data } = await api.userCentral.prevDataToManageCentrals(null)
            setDataToManage(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    useEffect(() => {
        if (isAdding) manageCentrals()
    }, [isAdding])

    // useEffect(() => {
    //     setType(form?.getFieldValue('type'))
    // }, [form])

    return (
        <Form disabled={onlyView} onFinish={onFinish} className="w-full flex flex-col settings-modal-configs-form-divisions" layout="vertical" requiredMark={false} form={form}>
            <div className="flex flex-col settings-modal-configs-form">
                <FormSubtitle message={displayMessage('IDENTIFICATION')} />
                <FormItem className={'flex flex-col w-full'} name="full_name" label={displayMessage('FIRST_NAME')}>
                    <SL_Input placeholder={displayMessage('FIRST_NAME')} className={'w-full font-bold shadow-md bg-black  settings-modal-configs-custom-input-height text-white'} />
                </FormItem>
                <FormItem className={'flex flex-col w-full'} name="email" label={displayMessage('EMAIL')}>
                    <SL_Input placeholder={displayMessage('EMAIL')} className={'w-full font-bold shadow-md bg-black  settings-modal-configs-custom-input-height text-white'} />
                </FormItem>
            </div>

            <div className="flex flex-col settings-modal-configs-form">
                <FormSubtitle message={displayMessage('ACESS')} />

                <div className="flex gap-[30px]">
                    <FormItem className={'flex flex-col w-full'} name="login" label={displayMessage('LOGIN')}>
                        <SL_Input placeholder={displayMessage('LOGIN')} className={'w-full font-bold shadow-md bg-black  settings-modal-configs-custom-input-height text-white'} />
                    </FormItem>
                    <FormItem className={'flex flex-col w-full'} name="password" required={isAdding ?? false} label={displayMessage('PASSWORD')}>
                        <SL_Input placeholder={displayMessage('PASSWORD')} className={'w-full font-bold shadow-md bg-black  settings-modal-configs-custom-input-height text-white'} type="password" />
                    </FormItem>
                    <FormItem className={'flex flex-col w-full'} name="confirm_password" required={isAdding ?? false} label={displayMessage('CONFIRM_PASSWORD')}>
                        <SL_Input
                            placeholder={displayMessage('CONFIRM_PASSWORD')}
                            className={'w-full font-bold shadow-md bg-black  settings-modal-configs-custom-input-height text-white'}
                            type="password"
                        />
                    </FormItem>
                </div>
                <FormItem className={'flex flex-col w-full'} name="type" label={displayMessage('ACCESS_LEVEL')} required={false}>
                    <GS_Select
                        mode="single"
                        disabled={onlyView}
                        className="w-full font-bold shadow-md bg-black rounded settings-modal-configs-custom-select-height text-white"
                        style={{ border: `1px solid ${colors['sl-transparency'][16]}` }}
                        options={Object.values(EUserTypes)}
                        value={form.getFieldsValue('type')}
                        placeholder={displayMessage('ACCESS_LEVEL')}
                        dropdownStyle={{ backgroundColor: colors['sl-gray'][700], color: 'white' }}
                    />
                </FormItem>
            </div>

            {isAdding && dataToManage && <ManageCentrals data={dataToManage} form={form} onManageCentrals={(e) => sendValues(e)} isAdding={true} />}
        </Form>
    )
}
