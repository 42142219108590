import { useContext, useEffect, useRef, useState } from 'react'

import atentionIcon from '../../../assets/icons/atention.svg'

import { getColorByLuminosity, getIcon } from '../../../helpers/helper'
import { displayMessage } from '../../../helpers/messages'
import { ECentralAlertTypes } from '../../../enums/ECentralAlertTypes'
export const Central = ({ data, onOpenCentral, onChangeVisibility, index, fromGroup = null, littleCentral = false }) => {
    const alertTypes = {}
    Object.values(ECentralAlertTypes).forEach((cat) => {
        alertTypes[cat.type] = cat
    })

    const [isHovered, setIsHovered] = useState(false)

    const handleIntersection = (entry) => {
        if (fromGroup || littleCentral) return
        if (![null, undefined].includes(data.priority)) {
            onChangeVisibility((current) => ({ ...current, [data.central_id]: { isVisible: entry.isIntersecting, ...data } }))
        }
    }

    const elementRef = useIntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
    })

    return (
        <div className={`relative ${littleCentral ? 'small-central-group' : `central-group`}`}>
            {data.offline && !isHovered && (
                <div className={`absolute flex justify-center items-center font-bold ${littleCentral ? 'small-central-group' : `central-group  gap-2`}`}>
                    <img src={atentionIcon} className={littleCentral ? 'small-central-offline-icon' : 'central-offline-icon'} />
                    <span className={` text-medium ${littleCentral ? 'small-central-offline-text' : 'central-offline-text'}`}>{displayMessage('OFFLINE')}</span>
                </div>
            )}
            <div
                id={index == 0 ? `${littleCentral ? 'little-central-' : ''}first_central` : `${littleCentral ? 'little-central-' : ''}central-${index}`}
                className={` bg-sl-gray-100  gradient-button cursor-pointer hover:opacity-100 ${data.offline ? 'opacity-15' : 'opacity-100'} ${
                    littleCentral ? 'small-central-group rounded' : `central-group rounded-2xl`
                }`}
                onClick={() => onOpenCentral(data.central_id)}
                ref={fromGroup ? null : elementRef}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div
                    style={{ backgroundColor: data.color, color: getColorByLuminosity(data.color) }}
                    className={`${littleCentral ? 'rounded-t small-central-title-div p-0.5' : 'rounded-t-2xl central-title-div'} w-full flex items-center justify-center `}
                >
                    <span className="truncate font-medium">{data.central_name}</span>
                </div>

                <div className={`grid grid-cols-2`}>
                    {Object.values(data?.alerts)
                        .filter((alert) => alert.monitoring)
                        .map((alert) => (
                            <div className={`flex flex-col items-center justify-center ${littleCentral ? 'small-central-alerts' : 'central-alerts'}`}>
                                <div className={`flex justify-center items-center ${littleCentral ? 'small-central-alerts-sygnals' : ' central-alerts-sygnals'}`}>
                                    {getIcon(alert.type, alert.color, littleCentral ? '0.5vh' : '2.96vh')}
                                    <div
                                        style={{ backgroundColor: alert.color, color: getColorByLuminosity(alert.color) }}
                                        className={`${littleCentral ? ' rounded-[270.92px] small-central-tag-quantity' : ' central-tag-quantity rounded-[1000px]'} flex items-center justify-center`}
                                    >
                                        <span className={`font-medium ${littleCentral ? 'small-central-tag-quantity-text' : ' central-tag-quantity-text'}`}>{alert.quantity}</span>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center">
                                    <span className={`${littleCentral ? 'small-central-alert-type' : 'central-alert-type'} uppercase text-medium`}>{alert.small_label ?? alert.label}</span>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

const useIntersectionObserver = (callback, options) => {
    const elementRef = useRef(null)

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                callback(entry)
            })
        }, options)

        if (elementRef.current) {
            observer.observe(elementRef.current)
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current)
            }
        }
    }, [callback, options])

    return elementRef
}
