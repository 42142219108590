import { useState } from 'react'
import { ConfigProvider, Table } from 'antd'
import { colors } from '../../../services/style'
import { displayMessage } from '../../../helpers/messages'
import './reports.css'

export const ReportTable = ({ columns, data, onShowMore, showMoreOptions, loading, height, limit }) => {
    const [offset, setOffet] = useState(0)

    columns = columns.map((col) => ({
        ...col,
        className: 'font-medium report-table-columns uppercase truncate text-ellipsis',
        render: (checked, record, index, originNode) => {
            if (col.custom_render) {
                return col.custom_render(record)
            }
            return <div className="report-table-lines flex normal-case text-white items-center truncate text-ellipsis px-2">{record?.[col.dataIndex] || displayMessage('EMPTY')}</div>
        },
    }))

    const getClasses = (idx) => {
        let classes = ' report-table-lines normal-case opacity-100 truncate text-ellipsis '
        const numberToRestOfOperation = 2
        if (idx == null || idx % numberToRestOfOperation == 0) return classes

        classes += 'rounded-[10px] bg-sl-gray-150'

        return classes
    }

    const showMoreAction = () => {
        const newOffset = offset + limit
        setOffet(newOffset)
        onShowMore({ limit, offset: newOffset })
    }
    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        headerSplitColor: 'transparent',
                        stickyScrollBarBg: 'tranparent',
                    },
                },
                token: {
                    colorBgContainer: colors['sl-gray'][1000],
                    colorText: 'white',
                    lineWidth: 0,
                    colorSplit: 'transparent',
                },
            }}
        >
            <Table
                columns={columns}
                dataSource={data}
                size="middle"
                scroll={{
                    y: height,
                    x: 500,
                }}
                rowHoverable={false}
                rowClassName={(record, index) => {
                    return getClasses(index + 1)
                }}
                pagination={false}
                loading={loading}
                footer={() =>
                    showMoreOptions ? (
                        <div className="flex justify-center items-center cursor-pointer" onClick={() => showMoreAction()}>
                            {displayMessage('SHOW_MORE')}
                        </div>
                    ) : null
                }
            />
        </ConfigProvider>
    )
}
