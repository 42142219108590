import { useContext, useEffect, useState } from 'react'

import { SL_Modal } from '../../../../components/modal/Modal'
import { SL_Button } from '../../../../components/buttons/Button'
import { SL_Checkbox } from '../../../../components/checkbox/Checkbox'

import { displayMessage } from '../../../../helpers/messages'
import { GeneralContext } from '../../../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../../../enums/EAlertTypes'
import { ECentralActions } from '../../../../enums/ECentralActions'
import { colors } from '../../../../services/style'
import api from '../../../../services/api'

export const ModalConfirmUserActions = ({ modalOption, onClose, currentCentral }) => {
    const { windowHeight, showAlert } = useContext(GeneralContext)

    const [activationGroups, setActivationGroups] = useState([])
    const [selectedGroupIds, setSelectedGroupIds] = useState([])
    const [firstSelectedGroupId, setFirstSelectedGroupId] = useState(null)

    useEffect(() => {
        if (modalOption == ECentralActions.GROUP_ALERT.code) {
            loadActivationGroups()
        }
    }, [])

    const loadActivationGroups = async () => {
        try {
            const { data } = await api.activationGroups.listByCentral(currentCentral)

            setActivationGroups(data)
        } catch (error) {
            console.error(error)
        }
    }

    const onConfirmSystemAction = async () => {
        try {
            await api.alertLogs.systemActions(modalOption, { group_ids: selectedGroupIds, central_id: currentCentral, from_painel: true })

            onClose()
        } catch (error) {
            console.log(error)
        }
    }

    const onSelectCard = (group_id) => {
        const currentActivationGroup = activationGroups.find((e) => e.id == group_id)

        if (selectedGroupIds.includes(group_id)) {
            setSelectedGroupIds(selectedGroupIds.filter((id) => id != group_id))
            if (selectedGroupIds.length == 0) {
                setFirstSelectedGroupId(null)
            }
        } else {
            if (selectedGroupIds.length == 0) {
                setFirstSelectedGroupId(currentActivationGroup)
                setSelectedGroupIds([group_id])
            } else {
                const firstSelectedActivationGroup = firstSelectedGroupId == null ? selectedGroupIds[0] : firstSelectedGroupId

                const haveTheSameActiveAlert = firstSelectedActivationGroup.has_active_alert == currentActivationGroup.has_active_alert

                if (haveTheSameActiveAlert) {
                    setSelectedGroupIds([...selectedGroupIds, group_id])
                } else {
                    showAlert(displayMessage('ERROR_TRY_CHANGE_ACTIVE_GROUPS'), EAlertTypes.INFO, displayMessage('ATTENTION'))
                }
            }
        }
    }

    return (
        <SL_Modal
            open={true}
            closable={false}
            closeIcon={null}
            width={'65vh'}
            footer={
                <div className="flex w-full items-end justify-end confirm-modal-default">
                    <SL_Button onClick={onClose} type="close" className="bg-white/10 hover:bg-white/20 confirm-modal-text confirm-modal-button">
                        {displayMessage('CANCEL')}
                    </SL_Button>
                    <SL_Button type="normal" onClick={onConfirmSystemAction} className="bg-primary-500 hover:bg-primary-100 confirm-modal-text confirm-modal-button">
                        {displayMessage('CONFIRM')}
                    </SL_Button>
                </div>
            }
            title={<div className="confirm-modal-text confirm-modal-default">{displayMessage('CONFIRM_ACTION')}</div>}
            onCancel={onClose}
            bodyStyle={{
                maxHeight: windowHeight * 0.7,
                padding: 12,
            }}
        >
            {activationGroups.length ? (
                <div className="grid gap-2 grid-cols-2 ">
                    {activationGroups.map((group) => (
                        <div
                            key={group.id}
                            className={`flex gap-2 p-2 ${(group.has_active_alert || selectedGroupIds.includes(group.id)) && 'bg-sl-red-200'}`}
                            style={{ border: `1px solid ${colors['sl-gray'][1000]}` }}
                        >
                            <SL_Checkbox onChange={() => onSelectCard(group.id)} className="rounded-full" checked={selectedGroupIds.includes(group.id)} />
                            <div className="flex flex-col confirm-modal-info">
                                <span className="font-bold">{group.name}</span>
                                <span>{group.warning_name}</span>
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
        </SL_Modal>
    )
}
