import { useContext } from 'react'
import { Tooltip } from 'antd'

import trashIcon from '../../../assets/icons/trash.svg'
import pencilIcon from '../../../assets/icons/pencil.svg'

import { SL_Confirm } from '../../../components/confirm/Confirm'

import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../../helpers/messages'

export const Option = ({ iconImg, icon, onClickTitle, title, onClickEdition, onConfirmDelete, deleteMessage, editTooltip, removeTooltip }) => {
    const { mobile_mode, big_mode } = useContext(GeneralContext)
    return (
        <div className={`w-full settings-modal-configs-option flex items-center gradient-button rounded flex-wrap justify-between`}>
            <div className="flex cursor-pointer items-center gap-[15px]" onClick={() => onClickTitle()}>
                {iconImg && <img src={iconImg} className="settings-modal-configs-option-icon" />}
                {icon && icon}

                <span className="text-white settings-modal-configs-option-label text-medium uppercase">{title}</span>
            </div>
            <div className="flex gap-[15px] items-center">
                <Tooltip title={<div className="settings-modal-configs-info-text">{editTooltip}</div>}>
                    <div className="settings-modal-configs-option-icon-div bg-sl-gray-100 flex justify-center items-center cursor-pointer" onClick={() => onClickEdition()}>
                        <img src={pencilIcon} className="settings-modal-configs-option-icon" />
                    </div>
                </Tooltip>
                <SL_Confirm
                    title={<div className="settings-modal-configs-info-text">{deleteMessage}</div>}
                    onConfirm={() => onConfirmDelete()}
                    okText={<div className="settings-modal-configs-info-text">{removeTooltip}</div>}
                    cancelText={<div className="settings-modal-configs-info-text">{displayMessage('CANCEL')}</div>}
                    placement="bottom"
                    tooltipTitle={<div className="settings-modal-configs-info-text">{removeTooltip}</div>}
                    icon={null}
                    cancelButtonProps={{
                        className: ' h-full',
                    }}
                    okButtonProps={{
                        className: 'h-full',
                    }}
                >
                    <div className="settings-modal-configs-option-icon-div bg-sl-gray-100 flex justify-center items-center cursor-pointer">
                        <img src={trashIcon} className="settings-modal-configs-option-icon" />
                    </div>
                </SL_Confirm>
            </div>
        </div>
    )
}
