import { Form } from 'antd'
import { displayMessage } from '../../../helpers/messages'

export const FormItem = ({ children, name, label, className, required = true }) => {
    const rules = []
    if (required) {
        rules.push({ required: true, message: displayMessage('REQUIRED_FIELD') })
    }
    return (
        <div className={`${className} settings-modal-configs-form-item`}>
            <div className="opacity-60 text-medium settings-modal-configs-form-label">{label}</div>

            <Form.Item name={name} rules={rules} className="mb-0">
                {children}
            </Form.Item>
        </div>
    )
}
