import { useContext } from 'react'
import { MagnifyingGlass } from '@phosphor-icons/react'

import { SL_Input } from '../../../components/input/Input'

import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

export const Search = ({ text, setText, placeholder, margin }) => {
    const { big_mode } = useContext(GeneralContext)
    return (
        <div className={`flex flex-col settings-modal-configs-search ${margin ? 'settings-modal-configs-search-margin' : ''}`}>
            <span className="settings-modal-configs-search-label">{displayMessage('SEARCH')}</span>
            <SL_Input
                placeholder={placeholder}
                className={'w-full font-bold shadow-md bg-black text-white settings-modal-configs-search-custom-input'}
                value={text}
                onChange={(e) => setText(e.target.value)}
                prefix={<MagnifyingGlass size={big_mode ? 30 : 16} />}
                allowClear={true}
            />
        </div>
    )
}
