import React, { useContext } from 'react'
import { SpeakerHigh, SpeakerSimpleX } from '@phosphor-icons/react'

import { SirenContext } from '../../context/SirenContext/SirenContext'
import { colors } from '../../services/style'

export function Player({}) {
    const { playSiren, setPlaySiren, isPlaying } = useContext(SirenContext)
    const color = isPlaying ? colors['sl-red'][200] : '#ffffff'
    return (
        <div
            className="bg-sl-gray-100 rounded-full flex justify-center items-center navbar-button cursor-pointer"
            style={{ border: `1px solid ${colors['sl-gray'][100]}` }}
            onClick={() => setPlaySiren(!playSiren)}
        >
            {playSiren ? (
                <SpeakerHigh color={color} className={`navbar-icon ${isPlaying ? 'animate-pulse' : ''}`} />
            ) : (
                <SpeakerSimpleX color={color} className={`navbar-icon ${isPlaying ? 'animate-pulse' : ''}`} />
            )}
        </div>
    )
}
