import { useContext, useEffect, useState } from 'react'
import { Form, Tag } from 'antd'

import { Search } from './Search'
import { CentralOnCentralSection } from './CentralOnCentralSection'

import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../../helpers/messages'
import { hexToRgba } from '../../../helpers/helper'
import { colors } from '../../../services/style'

export const ManageCentrals = ({ data, form, onManageCentrals, isAdding = false }) => {
    const { mobile_mode } = useContext(GeneralContext)
    const [showedCentrals, setShowedCentrals] = useState(data.centrals)
    const [checkedCentrals, setCheckedCentrals] = useState(data.centrals.filter((central) => central.checked).map((central) => central.id))
    const [text, setText] = useState(null)

    const doActionWithAllVisible = (checked_value) => {
        const ids = []
        showedCentrals.forEach((central) => {
            ids.push(central.id)
            form.setFieldValue(central.id, checked_value)
        })

        handleChanges(checked_value, ids)
        setText('')
    }

    const handleChanges = (checked_value, ids) => {
        let newCentrals
        if (checked_value) {
            newCentrals = checkedCentrals.concat(ids)
        } else {
            newCentrals = checkedCentrals.filter((central) => !ids.includes(central))
        }
        setCheckedCentrals([...new Set(newCentrals)])
    }

    const onChangeCheckbox = (central_id, checked_value) => {
        form.setFieldValue(central_id, checked_value)
        handleChanges(checked_value, [central_id])
    }

    useEffect(() => {
        form.setFieldsValue(data.data_to_form)
    }, [data])

    useEffect(() => {
        if (!text || text == '') {
            setShowedCentrals(data.centrals)
        } else {
            const newShowedCentrals = [...showedCentrals].filter((central) => {
                const { id, name } = central
                return `${id}`.includes(text) || name.toLowerCase().includes(text.toLowerCase())
            })

            setShowedCentrals(newShowedCentrals)
        }
    }, [text])

    return (
        <div className={`flex flex-col settings-modal-configs-central-management`}>
            {!isAdding && <Search placeholder={displayMessage('TO_SEARCH_CENTRALS')} setText={setText} text={text} />}
            <div className="flex flex-col gap-6">
                <div className="flex settings-modal-configs-central-management-title-div">
                    <span className="settings-modal-configs-central-management-title">{displayMessage(isAdding ? 'PERMISSIONS' : 'RESULTS')}</span>
                    <Tag
                        color={hexToRgba(colors.primary[600], 0.2)}
                        className="text-primary-600 cursor-pointer rounded settings-modal-configs-central-management-title-button flex items-center"
                        onClick={() => doActionWithAllVisible(true)}
                    >
                        <span className="info">{displayMessage('ADD_ALL')}</span>
                    </Tag>
                    <Tag
                        color={hexToRgba(colors['sl-red'][200], 0.2)}
                        className="text-sl-red-200 cursor-pointer rounded settings-modal-configs-central-management-title-button flex items-center"
                        onClick={() => doActionWithAllVisible(false)}
                    >
                        <span className="info">{displayMessage('REMOVE_ALL')}</span>
                    </Tag>
                </div>
                <Form onFinish={onManageCentrals} className="settings-modal-configs-central-section-centrals" layout="vertical" requiredMark={false} form={form}>
                    {showedCentrals.map((central) => (
                        <CentralOnCentralSection key={central.id} central={central} checkbox={true} checkedCentrals={checkedCentrals} onChangeCheckbox={onChangeCheckbox} />
                    ))}
                </Form>
            </div>
        </div>
    )
}
