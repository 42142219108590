import { useContext } from 'react'

import centralsIcon from '../../../assets/icons/central.svg'

import { SL_Button } from '../../../components/buttons/Button'

import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { CentralOnCentralSection } from './CentralOnCentralSection'

export const CentralsSection = ({ centrals = [], onManageCentrals }) => {
    const { mobile_mode } = useContext(GeneralContext)
    return (
        <div className="settings-modal-configs-central-section">
            <div className={`flex justify-between mb-2 settings-modal-configs-central-section-tile-div flex-wrap`}>
                <span className="text-white settings-modal-configs-central-section-tile"> {displayMessage('CENTRALS')}</span>
                <SL_Button
                    type="default"
                    className="bg-white/10 hover:bg-white/20 rounded settings-modal-configs-central-section-button text-white flex justify-center items-center"
                    onClick={() => onManageCentrals()}
                >
                    <span className="settings-modal-configs-central-section-button-text text-medium">{displayMessage('MANAGE_CENTRALS')}</span>
                </SL_Button>
            </div>

            <div className="settings-modal-configs-central-section-centrals ">
                {centrals.map((central) => (
                    <CentralOnCentralSection key={central.id} central={central} />
                ))}
            </div>
        </div>
    )
}
