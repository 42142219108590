import { useContext } from 'react'
import { X } from '@phosphor-icons/react'
import { DateTime } from 'luxon'

import { SL_Button } from '../../../components/buttons/Button'

import { colors } from '../../../services/style'
import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

import exportToExcel from '../../../services/export'

export const ModalTitle = ({ onCloseButtonClick, title, data, columns }) => {
    const { mobile_mode, windowWidth } = useContext(GeneralContext)

    const onExportClick = () => {
        const now = DateTime.now().toFormat('dd/MM/yyyy')
        const reportTitle = `${title}-${now}.xlsx`
        const columnsToExport = columns.filter((col) => !col.noExport)
        const reportData = data.map((action) => {
            const object = {}
            columnsToExport.forEach((col) => {
                object[col.title] = action[col.dataIndex]
            })

            return object
        })
        exportToExcel(reportData, reportTitle)
    }
    return (
        <div style={{ borderBottom: `1px solid ${colors['sl-gray'][100]}` }} className={`w-full report-table-title-div flex text-white flex-wrap justify-between items-center`}>
            <div className="flex gap-3">
                <X size={'2.22vh'} color={colors['sl-gray'][50]} className="cursor-pointer" onClick={onCloseButtonClick} />
                <h3 className="text-medium report-table-title">{title}</h3>
            </div>
            <SL_Button type="degault" className="bg-white/10 hover:bg-white/20 report-table-title-button rounded-[8px] px-4" onClick={() => onExportClick()}>
                {displayMessage('CSV_EXPORT')}
            </SL_Button>
        </div>
    )
}
