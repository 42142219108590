import { useContext, useEffect, useState } from 'react'

import { SL_Modal } from '../../components/modal/Modal'
import { ModalTitle } from './components/ModalTitle'
import { ChoseCentral } from './components/ChoseCentral'
import { ReportTable } from './components/ReportTable'
import { ShowSVG } from '../../components/showSVG/ShowSVG'

import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../helpers/messages'
import api from '../../services/api'

export const ActiveAlertsModal = ({ onClose }) => {
    const { windowHeight, windowWidth } = useContext(GeneralContext)
    const [actions, setActions] = useState(null)
    const [central, setCentral] = useState(null)

    const [showLoading, setShowLoading] = useState(false)

    const modalHeigth = windowHeight * 0.7

    const columns = [
        {
            dataIndex: 'icon',
            title: '',
            width: 60,
            ellipsis: true,
            noExport: true,
        },
        {
            dataIndex: 'description',
            title: displayMessage('DESCRIPTION'),
            width: 300,
            ellipsis: true,
        },
        {
            dataIndex: 'status_name',
            title: displayMessage('STATUS'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'name',
            title: displayMessage('FIRST_NAME'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'painel_date',
            title: displayMessage('DATE_AND_HOUR'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'first_name',
            title: displayMessage('DEVICE_FIRST_NAME'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'second_name',
            title: displayMessage('DEVICE_LAST_NAME'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'setor',
            title: displayMessage('ZONE_TITLE'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'location',
            title: displayMessage('LOCATION_TITLE'),
            width: 240,
            ellipsis: true,
        },
    ]

    const getReportData = async (params = {}, id = central) => {
        setShowLoading(true)
        try {
            const { data } = await api.activeAlerts.reportsByCentral(id)

            const newActions = data.map((action) => ({ ...action, icon: <ShowSVG icon={action.icon} className="report-table-icon" /> }))
            setActions((current) => (!current ? newActions : current.concat(newActions)))
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    useEffect(() => {
        if (central) {
            setActions(null)
            getReportData({}, central)
        }
    }, [central])

    return (
        <SL_Modal
            open={true}
            title={<ModalTitle onCloseButtonClick={onClose} title={displayMessage('ACTIVE_ALERT_TITLE')} data={actions} columns={columns} />}
            closable={false}
            closeIcon={null}
            footer={null}
            width={windowWidth * 0.9}
            bodyStyle={{
                height: modalHeigth,
                padding: '2.22vh',
            }}
        >
            <ChoseCentral onChangeCentral={setCentral} />
            {actions && <ReportTable columns={columns} data={actions} height={modalHeigth - 100} onShowMore={getReportData} showMoreOptions={false} loading={showLoading} />}
        </SL_Modal>
    )
}
