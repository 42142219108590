import { useEffect, useState } from 'react'
import { GS_Select } from '../../../components/select/GS_Select'
import { displayMessage } from '../../../helpers/messages'
import { colors } from '../../../services/style'
import api from '../../../services/api'

export const ChoseCentral = ({ onChangeCentral, showLoading }) => {
    const [centrals, setCentrals] = useState(null)
    const [selectLoading, setSelectLoading] = useState(false)

    const getCentrals = async () => {
        setSelectLoading(true)
        try {
            const { data } = await api.centrals.listbyUserAccess()
            setCentrals(data.map((central) => ({ value: central.id, label: central.name })))
        } catch (error) {
            console.log(error)
        } finally {
            setSelectLoading(false)
        }
    }

    useEffect(() => {
        getCentrals()
    }, [])
    return (
        <div className="flex flex-wrap gap-2 items-center mb-1">
        <span className="text-white chose-central-text">{displayMessage('CHOSE_CENTRAL')}</span>
            <GS_Select
                mode="single"
                className="font-bold shadow-md bg-sl-transparency-4 text-white rounded-[8px] custom-input chose-central-custom-input-heigth chose-central-select"
                placeholder={displayMessage('CENTRAL')}
                dropdownStyle={{ backgroundColor: colors['sl-gray'][700], color: 'white' }}
                onChange={(e) => onChangeCentral(e)}
                options={centrals}
                disabled={showLoading || selectLoading}
            />
        </div>
    )
}
