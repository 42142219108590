import { useContext, useState } from 'react'
import { ConfigProvider, Dropdown, Layout, Watermark } from 'antd'
import { Link, Outlet } from 'react-router-dom'
import { Bell, Gear, List, UserCircle } from '@phosphor-icons/react'
import { PersonCircleOutline } from 'react-ionicons'
import { AuthContext } from '../../../context/AuthContext/AuthContext'
import { displayMessage } from '../../../helpers/messages'
import { colors } from '../../../services/style'
import { SettingsModal } from '../../../screens/settingsModal/SettingsModal'
import { DropdownMenu } from '../menus/DropdownMenu'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import logo from '../../../assets/small-logo.svg'
import { Player } from '../../../components/sound/Player'
import { InternalLayoutLoader } from '../InternalLayoutLoader'

export const InternalLayout = () => {
    const { onChangeIsLogged, currentUser } = useContext(AuthContext)
    const { windowHeight, windowWidth } = useContext(GeneralContext)

    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)

    const handleOnClickLogout = async () => {
        await onChangeIsLogged(false)
    }

    const items = [
        {
            key: '/logout',
            label: (
                <Link to={'/login'} onClick={handleOnClickLogout} className='flex nav-bar-menu-option-gap items-center'>
                    <span className="ml-1 font-medium navbar-user-name">{displayMessage('LOGOUT')}</span>
                </Link>
            ),
            style: { width: '100%' },
        },
    ]

    return (
        <div className="bg-sl-gray-1000 h-screen w-full monitor">
            {isSettingsModalOpen && <SettingsModal onClose={() => setIsSettingsModalOpen(false)} />}
            <Watermark
                zIndex={100}
                rotate={0}
                color={colors['sl-gray'][150]}
                image={logo}
                height={windowHeight}
                width={windowHeight}
                offset={[windowWidth - windowHeight, 0]}
                className=" h-screen w-full"
            >
                <Layout className="bg-sl-gray-900 h-full w-full">
                <InternalLayoutLoader />
                    <div className="flex flex-row justify-between items-center w-full bg-sl-gray-1000 py-4 px-6 navbar">
                        <div>
                            <DropdownMenu />
                        </div>
                        <div className="flex flex-row items-center navbar-icons mr-1 navbar-icons">
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorBgBase: colors['sl-gray'][900],
                                        colorText: colors['sl-gray'][50],
                                        borderRadius: '12px',
                                        lineHeight: '12px',
                                    },
                                }}
                            >
                                <Dropdown className="bg-sl-gray-100" menu={{ items }}>
                                    <div className="flex flex-row justify-start bg-sl-gray-100 rounded-[100px] navbar-user items-center" style={{ border: `1px solid ${colors['sl-gray'][100]}` }}>
                                        <UserCircle color={'#ffffff'} className="navbar-user-icon" />
                                        <p className=" text-white ml-1 font-medium navbar-user-name">{currentUser?.first_name}</p>
                                    </div>
                                </Dropdown>
                            </ConfigProvider>
                            <Player />
                            <div className="bg-sl-gray-100 rounded-full flex justify-center items-center navbar-button cursor-pointer" style={{ border: `1px solid ${colors['sl-gray'][100]}` }}>
                                <Bell weight="fill" color={'#ffffff'} className="navbar-icon" />
                            </div>
                            <div
                                className="bg-sl-gray-100 rounded-full flex justify-center items-center navbar-button cursor-pointer"
                                onClick={() => setIsSettingsModalOpen(true)}
                                style={{ border: `1px solid ${colors['sl-gray'][100]}` }}
                            >
                                <Gear weight="fill" color={'#ffffff'} className="navbar-icon" />
                            </div>
                        </div>
                    </div>

                    <div className={`flex flex-col flex-1 p-2 smb:p-4 smb:w-unset overflow-auto h-full w-full`}>
                        <Outlet />
                    </div>
                </Layout>
            </Watermark>
        </div>
    )
}
