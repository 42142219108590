import { useContext, useEffect, useState } from 'react'
import { Badge, ConfigProvider, Tabs } from 'antd'

import { SL_Modal } from '../../../../components/modal/Modal'
import { ShowSVG } from '../../../../components/showSVG/ShowSVG'

import { displayMessage } from '../../../../helpers/messages'
import { getColorByLuminosity, getIcon, hexToRgba } from '../../../../helpers/helper'
import { GeneralContext } from '../../../../context/GeneralContext/GeneralContext'
import { colors } from '../../../../services/style'
import { ECentralActions } from '../../../../enums/ECentralActions'
import { X } from '@phosphor-icons/react'

export const CentralModal = ({ data, onClose, onClickUserActionButton }) => {
    const { windowHeight, windowWidth, mobile_mode } = useContext(GeneralContext)
    const modalVW = 56.25

    const modalWidth = mobile_mode ? windowWidth * 0.9 : windowWidth * (modalVW / 100)
    const [sectorActive, setSectorActive] = useState(data?.sectors?.[0])

    const generateAlertStyles = (sector) => {
        const sectorsStyle = {
            backgroundColor: '',
            border: '',
        }

        const activeStyles = {
            backgroundColor: hexToRgba(sector.devices?.[0]?.color, 0.4),
            border: `solid 1.5px ${sector.devices?.[0]?.color}`,
        }
        return sector.alert_counts ? activeStyles : sectorsStyle
    }

    const generalActions = Object.values(ECentralActions)
    const sectors = data.sectors.map((sector) => ({
        label: (
            <div id={`sector-${sector.id}`} className="flex items-center gap-1 p-1 rounded" style={generateAlertStyles(sector)}>
                <span className="text-medium central-modal-title-tabs-text">{sector.name}</span>

                {sector.alert_counts ? <Badge count={sector.alert_counts} color={sector.devices?.[0]?.color} className="teste" style={{ color: 'white', boxShadow: 'none' }} /> : null}
            </div>
        ),
        key: sector.id,
    }))

    const onChangeTab = (value) => {
        const active = data.sectors.find((sec) => sec.id == value)
        setSectorActive(active)
    }

    const title = (
        <div className={`flex justify-between flex-wrap central-modal-title-div text-white`}>
            <span className="mt-2 font-medium central-modal-title">{data.central_name}</span>

            <div className="flex mb-1">
                <ConfigProvider
                    theme={{
                        token: {
                            colorBorder: 'transparent',
                            colorBorderSecondary: 'transparent',
                            colorPrimaryBorder: 'transparent',
                        },
                        components: {
                            Tabs: {
                                itemSelectedColor: colors['sl-gray'][50],
                                itemActiveColor: colors['sl-gray'][50],
                                itemHoverColor: colors['sl-gray'][50],
                                inkBarColor: colors['sl-gray'][50],
                                horizontalItemPadding: '0px 0px 5px 0px',
                                horizontalItemGutter: 10,
                            },
                        },
                    }}
                >
                    <Tabs
                        defaultActiveKey={data?.sectors?.[0]?.key}
                        className={`${mobile_mode ? 'max-w-full' : ''} central-modal-title-tabs`}
                        items={sectors}
                        onTabClick={onChangeTab}
                        activeKey={sectorActive?.key}
                    />
                </ConfigProvider>
                <X color={colors['sl-gray'][50]} className="cursor-pointer settings-modal-configs-icon" onClick={onClose} />
            </div>
        </div>
    )

    const getGeneralButtonColor = (key) => {
        const { should_activate_general_alert_buttton, should_activate_group_alert_button } = data.general_buttons_data

        switch (key) {
            case ECentralActions.GENERAL_ALERT.code:
                if (should_activate_general_alert_buttton) return 'bg-sl-red-200'
                break
            case ECentralActions.GROUP_ALERT.code:
                if (should_activate_group_alert_button) return 'bg-sl-red-200'
                break
        }
        return 'gradient-button'
    }

    useEffect(() => {
        onChangeTab(data?.sectors?.[0]?.id)
    }, [])

    return (
        <SL_Modal
            open={true}
            closable={false}
            footer={
                <div className={`grid gap-2 grid-cols-5`} style={{ borderTop: `1.5px solid ${colors['sl-gray'][150]}` }}>
                    {Object.values(data.count_by_alert).map((alert) => (
                        <div>
                            <div className="flex flex-col central-modal-general-footer items-center justify-center">
                                <div className="flex justify-center items-center central-modal-general-footer-symbols">
                                    {getIcon(alert.type, alert.color, '2.22vh')}
                                    {alert.quantity ? (
                                        <div style={{ backgroundColor: alert.color, color: getColorByLuminosity(alert.color) }} className="rounded-full central-modal-general-footer-quantity">
                                            <span className="font-semibold">{alert.quantity}</span>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="flex justify-center items-center uppercase">
                                    <span className="central-modal-general-footer-alert">{alert.label}</span>
                                </div>
                            </div>
                            <div
                                style={
                                    alert.quantity
                                        ? {
                                              boxShadow: `${hexToRgba(alert.color, 0.5)} 0px -6px 8px 4px`,
                                              height: 0,
                                              margin: '0px 10px',
                                              backgroundColor: 'transparent',
                                          }
                                        : { display: 'none' }
                                }
                            ></div>
                        </div>
                    ))}
                </div>
            }
            width={modalWidth}
            title={title}
        >
            <div className="flex flex-col gap-3">
                <div
                    className={`grid grid-cols-4  central-modal-general-actions`}
                    style={{ borderTop: `1.5px solid ${colors['sl-gray'][150]}`, borderBottom: `1.5px solid ${colors['sl-gray'][150]}` }}
                >
                    {generalActions.map((action) => (
                        <div
                            key={action.label}
                            onClick={() => onClickUserActionButton(action.code)}
                            className={` rounded flex flex-col  items-center justify-center w-full cursor-pointer central-modal-general-action ${getGeneralButtonColor(action.code)}`}
                        >
                            <img src={action.icon} className="central-modal-general-action-icon" />
                            <span className="text-medium central-modal-general-action-text">{action.label}</span>
                        </div>
                    ))}
                </div>
                <div className="pr-4">
                    <div className={`${sectorActive?.devices.length ? 'grid gap-2 grid-cols-2' : ''} custom-scroll pr-1 -mr-2 central-modal-devices`} style={{ overflow: 'auto' }}>
                        {sectorActive?.devices.length ? (
                            sectorActive?.devices.map((device) => (
                                <div className="flex gradient-button h-[80px] rounded p-1 w-full items-center">
                                    <div className="rounded m-1 flex items-center justify-center h-[55px]" style={{ backgroundColor: device.color }}>
                                        <ShowSVG className="report-table-icon" icon={device.icon} />
                                    </div>
                                    <div key={device.key} className={`flex flex-col`}>
                                        <span>{device.first_name}</span>
                                        <span>{device.second_name}</span>
                                        <span>{displayMessage('LOCATION', [device.location_description ?? displayMessage('EMPTY')])}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="h-full w-full flex justify-center items-center"> {displayMessage('NO_DEVICES')}</div>
                        )}
                    </div>
                </div>
            </div>
        </SL_Modal>
    )
}
