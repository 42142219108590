import { useEffect, useState } from 'react'
import { SirenContext } from './SirenContext'

import officialActivation from '../../sirenSongs/manualActivation.wav'
import useSound from 'use-sound'

export function SirenContextProvider({ children }) {
    const [playSiren, setPlaySiren] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)

    const [playOfficialActivation, { stop: stopOfficialActivation, sound }] = useSound(officialActivation)

    useEffect(() => {
        verifyIfShouldPlaySirenAlert()
    }, [playSiren, isPlaying, sound])

    function verifyIfShouldPlaySirenAlert() {
        if (playSiren && sound && isPlaying) {
            playOfficialActivation()

            sound.loop(true)
        }
        if (!playSiren || !isPlaying) {
            stopOfficialActivation()
        }
    }

    return (
        <SirenContext.Provider
            value={{
                // SirenSong
                playSiren,
                setPlaySiren,
                isPlaying,
                setIsPlaying,
            }}
        >
            {children}
        </SirenContext.Provider>
    )
}
