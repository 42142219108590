import { Form, Switch } from 'antd'
import { colors } from '../../../../services/style'

export const Option = ({ title, description, onChange, checked, formName }) => {
    return (
        <div
            className="rounded-[8px] settings-modal-configs-notifications-option flex justify-between bg-sl-transparency-16 items-center"
            style={{ border: `1.5px solid ${colors['sl-transparency'][8]}` }}
        >
            <div className="flex flex-col gap-1">
                <span className="settings-modal-configs-notifications-option-title">{title}</span>
                <span className="settings-modal-configs-notifications-option-description">{description}</span>
            </div>
            {formName ? (
                <Form.Item name={formName} className="mb-0">
                    <Switch onChange={onChange} className="w-[50px] rounded-[100px]" defaultChecked={checked} />
                </Form.Item>
            ) : (
                <Switch onChange={onChange} className="w-[50px] rounded-[100px]" defaultChecked={checked} />
            )}
        </div>
    )
}
