module.exports = {
    colors: {
        primary: {
            100: '#7cc2ff',
            500: '#005db4',
            600: '#1c71f1',
        },

        'sl-red': {
            100: 'rgba(238, 63, 84, 0.4)',
            200: '#EE3F54',
        },
        'sl-gray': {
            0: '#ffffff',
            50: '#f5f5f4',
            100: 'rgba(255, 255, 255, 0.08)',
            150: 'rgba(255, 255, 255, 0.2)',
            300: '#a3a3a3',
            400: '#737373',
            700: '#494950',
            900: '#2E2F34',
            1000: '#161618',
        },
        'sl-transparency': {
            4: 'rgba(255, 255, 255, 0.04)',
            8: 'rgba(255, 255, 255, 0.08)',
            12: 'rgba(255, 255, 255, 0.12)',
            16: 'rgba(255, 255, 255, 0.16)',
            24: 'rgba(255, 255, 255, 0.24)',
            40: 'rgba(255, 255, 255, 0.40)',
        },
        'sl-yellow': {
            50: '#F5AC1E',
        },
        'sl-green': {
            200: '#16a34a',
        },
    },
}
